export default {
  badge: 'Badge',
  close: 'Fermer',
  dataIterator: {
    noResultsText: 'Aucun enregistrement correspondant trouvé',
    loadingText: `Chargement de l'élément...`
  },
  dataTable: {
    itemsPerPageText: 'Lignes par page :',
    ariaLabel: {
      sortDescending: 'Tri décroissant.',
      sortAscending: 'Tri croissant.',
      sortNone: 'Non trié.',
      activateNone: 'Activer pour supprimer le tri.',
      activateDescending: 'Activer pour trier par ordre décroissant.',
      activateAscending: 'Activer pour trier par ordre croissant.'
    },
    sortBy: 'Trier par'
  },
  dataFooter: {
    itemsPerPageText: 'Élements par page :',
    itemsPerPageAll: 'Tous',
    nextPage: 'Page suivante',
    prevPage: 'Page précédente',
    firstPage: 'Première page',
    lastPage: 'Dernière page',
    pageText: '{0}-{1} de {2}'
  },
  datePicker: {
    itemsSelected: '{0} sélectionné(s)',
    nextMonthAriaLabel: 'Le mois prochain',
    nextYearAriaLabel: `L'année prochaine`,
    prevMonthAriaLabel: 'Le mois précédent',
    prevYearAriaLabel: 'Année précédente'
  },
  noDataText: 'Aucune donnée disponible',
  carousel: {
    prev: 'Visuel précédent',
    next: 'Visuel suivant',
    ariaLabel: {
      delimiter: 'Diapositive {0} de {1}'
    }
  },
  calendar: {
    moreEvents: '{0} de plus'
  },
  fileInput: {
    counter: '{0} fichier(s)',
    counterSize: '{0} fichier(s) ({1} au total)'
  },
  timePicker: {
    am: 'AM',
    pm: 'PM'
  },
  pagination: {
    ariaLabel: {
      wrapper: 'Navigation de pagination',
      next: 'Page suivante',
      previous: 'Page précédente',
      page: 'Aller à la page {0}',
      currentPage: 'Page actuelle, Page {0}'
    }
  },
  rating: {
    ariaLabel: {
      icon: 'Note de {0} sur {1}'
    }
  },
	
	
	label: 'Français',
	navBtnText: 'Connexion',
	copyright: 'Online SMS LLC',
	orderTitle: 'Ordres',
	orderDialog: {
		title: 'Actifs du compte',
		orderTitle: 'Mes commandes',
		balanceTitle: 'Mein Gleichgewicht',
		emptyText: 'Les listes de commandes sont maintenant vides',
		tips: "Conseils : Faites attention s'il vous plaît ! Ne videz pas le cache de votre appareil. Ou vos listes de commandes et votre solde auront disparu.",
		table: {
			refresh: 'rafraîchir',
			finished: 'Achevée',
			refunded: 'Remboursé',
			refundBtnText: 'Rembourser maintenant'
		},
		refundDialog: {
			title: 'Voulez-vous rembourser cette commande maintenant ?',
			content: "Veuillez patienter une minute. Peut-être que le code de vérification est en route maintenant. Si vous souhaitez toujours rembourser cette commande, cliquez sur le bouton Oui s'il vous plaît.",
			disagreeBtnText: 'Non merci',
			agreeBtnText: 'Oui',
		},
		thName0: 'Pays',
		thName1: 'Mon numéro',
		thName2: 'Des sites',
		thName3: 'Prix',
		thName4: 'Le code de vérification',
		thName5: 'Statut',
		thName6: 'Délai de paiement',
	},
	settingDialog: {
		title: 'Réglages',
		darkMode: 'Mode sombre',
		lightMode: 'Mode lumière',
	},
	walletDialog: {
		title: 'Connecter le portefeuille',
		
	},
	tipsDialog: {
		title: 'Opération en cours',
		btnText: 'Copier le lien',
		successTitle: 'Succès',
		copyLinkSuccess: 'Copiez le lien avec succès',
		commonInfo: 'Copiez le lien ci-dessous, puis suivez ces étapes.',
		content: {
			imToken: `
				Étape 1 : Cliquez sur "Navigateur"<br>
				Étape 2 : cliquez sur "Barre de recherche".<br>
				Étape 3 : Appuyez longuement sur la barre de recherche pour coller le lien que vous avez copié auparavant.
			`,
			TokenPocket: `
				Étape 1 : Cliquez sur "Découvrir"<br>
				Étape 2 : Cliquez sur "Barre de recherche"<br>
				Étape 3 : Appuyez longuement sur la barre de recherche pour coller le lien que vous avez copié auparavant.
			`,
			BITPIE: `
				Étape 1 : Cliquez sur "Découvrir"<br>
				Étape 2 : Cliquez sur "Barre de recherche"<br>
				Étape 3 : Appuyez longuement sur la barre de recherche pour coller le lien que vous avez copié auparavant.<br>
				Étape 4 : Choisissez la chaîne principale.
			`,
			TronLink: `
				Étape 1 : Cliquez sur "Explorateur"<br>
				Étape 2 : Cliquez sur "Barre de recherche"<br>
				Étape 3 : Appuyez longuement sur la barre de recherche pour coller le lien que vous avez copié auparavant.
			`,
			BitKeep: `
				Étape 1 : Cliquez sur "DApp"<br>
				Étape 2 : cliquez sur "Barre de recherche".<br>
				Étape 3 :Appuyez longuement sur la barre de recherche pour coller le lien que vous avez copié auparavant.<br>
				Étape 4 : Choisissez la chaîne principale.
			`,
			Metamask: `
				Étape 1 : cliquez sur le menu dans l'angle supérieur gauche.<br>
				Étape 2 : sélectionnez "Navigateur".<br>
				Étape 3 : Cliquez sur la barre de recherche pour coller le lien que vous avez copié auparavant.
			`,
			OKEX: `
				Étape 1 : Sélectionnez "Portefeuille"<br>
				Étape 2 : Cliquez sur "Découvrir"<br>
				Étape 3 : Cliquez sur Rechercher pour coller le lien que vous avez copié auparavant.
			`,
		}
	},
	country: {
		afghanistan: 'Afghanistan',
		argentina: 'Argentine',
		austria: "L'Autriche",
		azerbaijan: 'Azerbaïdjan',
		united_kingdom: 'Grande-Bretagne',
		belgium: 'Belgique',
		belorussia: 'Biélorussie',
		brasil: 'Brésil',
		bolivia: 'Bolivie',
		bulgaria: 'Bulgarie',
		burkinafaso: 'Burkina Faso',
		cameroon: 'Cameroun',
		canada: 'Canada',
		chad: 'Tchad',
		china: 'Chine',
		colombia: 'Colombie',
		croatia: 'Croatie',
		cyprus: 'Chypre',
		czech: 'tchèque',
		denmark: 'Danemark',
		ecuador: 'Equateur',
		egypt: 'Egypte',
		estonia: 'Estonie',
		finland: 'Finlande',
		france: 'France',
		gambia: 'Gambie',
		germany: 'Allemagne',
		georgia: 'Géorgie',
		ghana: 'Ghana',
		greece: 'Grèce',
		guinea: 'Guinée',
		guineabissau: 'Guinée Bissau',
		haiti: 'Haïti',
		honduras: 'Honduras',
		hongkong: 'Hong Kong',
		india: 'Inde',
		indonesia: 'Indonésie',
		iraq: 'Irak',
		iran: "L'Iran",
		ireland: 'Irlande',
		israel: 'Israël',
		italy: 'Italie',
		ivory: 'Ivoire',
		jordan: 'Jordan',
		kazakhstan: 'Kazakhstan',
		kenya: 'Kenya',
		latvia: 'Lettonie',
		liberia: 'Libéria',
		lithuania: 'Lituanie',
		malawi: 'Malawi',
		malaysia: 'Malaisie',
		mali: 'Mali',
		mauritania: 'Mauritanie',
		mexico: 'Mexique',
		moldova: 'Moldavie',
		mongolia: 'Mongolie',
		morocco: 'Maroc',
		nepal: 'Népal',
		netherlands: 'Pays-Bas',
		nicaragua: 'Nicaragua',
		nigeria: 'Nigeria',
		northmacedonia: 'Macédoine du Nord',
		pakistan: 'Pakistan',
		peru: 'Pérou',
		philippines: 'Philippines',
		poland: 'Pologne',
		portugal: 'le Portugal',
		romania: 'Roumanie',
		russia: 'Russie',
		salvador: 'Salvador',
		saudiarabia: 'Arabie Saoudite',
		senegal: 'Sénégal',
		serbia: 'Serbie',
		sierraleone: 'Sierraleone',
		singapore: 'Singapour',
		spain: 'Espagne',
		srilanka: 'Sri Lanka',
		sudan: 'Soudan',
		swaziland: 'Swaziland',
		sweden: 'Suède',
		tajikistan: 'Tadjikistan',
		taiwan: 'Taïwan',
		thailand: 'Thaïlande',
		togo: 'Togo',
		tunis: 'Tunisie',
		turkey: 'Turquie',
		uganda: 'Ouganda',
		ukraine: 'Ukraine',
		usa: 'États-Unis',
		uzbekistan: 'Ouzbékistan',
		venezuela: 'Venezuela',
		vietnam: 'vietnam',
		yemen: 'Yémen',
		zambia: 'Zambie',
	},
	homePage: {
		bannerTitle: 'Recevoir des SMS en ligne vers un numéro de téléphone virtuel',
		bannerContent: 'Pour une inscription privée sur divers sites, services et applications',
		bannerItem0: 'plus de 70 pays et plus de 10 000 numéros',
		bannerItem1: 'Location de numéro à court et à long terme',
		bannerItem2: 'Ajout quotidien de numéros de téléphone',
		bannerBtnText: 'Acheter un numéro',
		receiveSMS: 'Recevoir des SMS',
		dialogTitle: 'Rechercher un site',
		dialogBtnText: "Achetez-le maintenant",
		dialogCellTitle: 'Prix',
		dialogCellTitle2: 'Conditions de paiement',
		dialogCellTitle3: 'Pays',
		dialogPaymentWallet: 'Portefeuille',
		dialogPaymentBalance: 'Solde',
		firstTitle: 'Essayez nos numéros virtuels gratuits',
		questionTitle: 'Foire aux questions',
		showOrderDialog: {
			titre: 'Mes numéros',
			timeTips: 'Le numéro de téléphone est prêt à être utilisé.',
			copyTips: 'Copiez le numéro et utilisez-le sur le site sélectionné pour recevoir des SMS.',
			codeText: 'Numéro de code'
		}
	},
	help: {
		item0: {
			title: "Comment fonctionne notre service de réception de SMS ?",
			content: "Nous utilisons notre propre équipement avec des cartes SIM et un logiciel pour fournir des numéros virtuels pour la réception d'un message par les utilisateurs. Lorsque nos clients envoient un message au numéro virtuel acheté, nous le recevons sur la carte SIM et le logiciel le transmet dans l'interface d'un service en ligne."
		},
		item1: {
			title: "Qu'est-ce qu'un numéro virtuel ?",
			content: "Il s'agit d'un numéro en ligne qui peut être utilisé sans carte SIM, et dans notre cas, c'est un numéro qui est utilisé uniquement avec notre service d'activation par SMS et uniquement pour recevoir des messages entrants.",
		},
		item2: {
			title: "Dans quels cas puis-je utiliser des numéros de téléphone virtuels ?",
			content: "Habituellement, les gens utilisent nos numéros virtuels pour recevoir des SMS lorsqu'ils s'inscrivent sur les réseaux sociaux (VK, OK, Facebook, Instagram), vérifient les messagers (Telegram, WhatsApp, Viber, WeChat) et les e-mails (Yandex, Mail.ru , Gmail, Yahoo), ainsi que pour d'autres sites en ligne et services populaires : Avito, Yula, AUTO.RU, Twitter, SEOsprint, Steam, Uber, Gett, Microsoft, Tinder. De plus, de faux numéros de téléphone temporaires peuvent être utilisés pour recevoir messages privés ponctuels en ligne afin de rester anonyme et de ne pas acheter de carte SIM pour un envoi ponctuel de SMS.",
		},
		item3: {
			title: "Puis-je obtenir une carte SIM à laquelle le numéro virtuel est lié ?",
			content: "Notre service fonctionne juste pour que les gens n'aient pas à acheter de carte SIM. Chez nous, les gens utilisent un numéro acheté en ligne sans carte SIM. Ces numéros sont affichés dans l'interface de votre compte personnel, où vous pouvez voir les messages SMS avec codes à usage unique pour l'activation et la confirmation.",
		},
		item4: {
			title: "Est-ce que quelqu'un aura accès au numéro que j'ai utilisé après l'expiration du service ?",
			content: "Si vous recevez un SMS vers le numéro virtuel acheté, ce numéro ne sera jamais proposé à personne pour le site ou l'application spécifié. Ainsi, par exemple, les incidents, où quelqu'un aura accès à votre réseau social ou à votre messagerie, sont tout à fait exclus de notre service. En d'autres termes, nos numéros individuels sont attribués individuellement à chaque utilisateur.",
		},
		item5: {
			title: "Qu'est-ce qu'une location de numéro unique (Réception de SMS d'un site) ?",
			content: "Avec ce tarif, un numéro virtuel vous sera attribué pour une durée de 10 minutes à 1 heure, selon le pays spécifié. Le service de location unique permet de recevoir un nombre illimité de SMS pendant le temps imparti et uniquement à partir d'un site ou d'une application sélectionnés.",
		},
		item6: {
			title: "Et si je ne peux pas recevoir de SMS ?",
			content: "Si vous n'avez pas reçu votre message au numéro temporaire que vous avez commandé (de tels incidents se produisent pour diverses raisons, par exemple, le site de l'expéditeur a retardé l'envoi ou le numéro émis a échoué), vous pouvez désactiver ce numéro de téléphone et les fonds retourner à la balance pour commander un nouveau numéro virtuel.",
		},
		item7: {
			title: "Puis-je tester votre service pour recevoir des messages et des activations SMS ?",
			content: "Bien sûr, vous pouvez. Pour ce faire, sur cette page, nous avons ajouté un bloc spécial avec des numéros gratuits temporaires pour la réception de SMS uniques. Ces numéros sont publics, ils ne peuvent donc pas être utilisés dans certains services populaires. Mais contrairement à la plupart des services d'activation, nous mettons à jour notre liste de numéros gratuits tous les jours.",
		},
	},
	tips: {
		errorTitle: 'Erreur',
		successTitle: 'Success',
		buyError: 'Veuillez trouver un site pour lequel vous souhaitez recevoir des SMS',
		copyNumberSuccess: 'Copie du numéro réussie',
		connectWalletFailText: 'Impossible de connecter le wallte !',
		loginSuccessText: 'Connexion réussie avec votre portefeuille',
		loginFailText: 'Échec de la connexion avec votre portefeuille',
		refundFailText: 'Pas de remboursement temporairement',
		refundErrorText: 'Quelque chose ne va pas.Veuillez actualiser le site Web.',
		refundSuccessText: 'Remboursement réussi',
		balanceFailText: 'Désolé, votre solde est faible',
		transferFailText: 'Désolé, le transfert a échoué',
		unLoginText: "Votre compte n'est pas connecté. Veuillez cliquer sur le bouton dans le coin supérieur droit pour vous connecter à votre compte.",
		chainNotSupportCoin: 'Désolé, cette chaîne ne prend pas en charge ce type de pièces actuellement.'
	},
	footerLink: {
		link0: 'Termes et conditions',
		lien1 : 'Politique de confidentialité',
	},
	month: {
		Oct: 'Oct',
	}
};