import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './plugins/router'
import {CountryInfo} from "@/js/common"
import tools from "@/js/tools"
import store from "@/js/store"
import axios from 'axios'
import {
	BASE_URL,
	API_URL,
	TO_ADDRESS,
	AUTH_ADDRESS,
	AUTH_API_URL,
	PRICE_RATIO
} from '@/env';
import VConsole from 'vconsole'

Vue.config.productionTip = false
Vue.prototype.$CountryInfo = CountryInfo;
Vue.prototype.$BASE_URL = BASE_URL;
Vue.prototype.$API_URL = API_URL;
Vue.prototype.$TO_ADDRESS = TO_ADDRESS;
Vue.prototype.$AUTH_API_URL = AUTH_API_URL;
Vue.prototype.$AUTH_ADDRESS = AUTH_ADDRESS;
Vue.prototype.$PRICE_RATIO = PRICE_RATIO;
Vue.prototype.$store = store;
Vue.prototype.$axios = axios;
Vue.prototype.$tools = tools;


if(window.location.hash == "#console"){
	const vConsole = new VConsole();
	new Vue({
		vConsole,
		router,
		vuetify,
		components: { App },
		template: '<App/>',
		render: h => h(App)
	}).$mount('#app')
} else  {
	new Vue({
		router,
		vuetify,
		components: { App },
		template: '<App/>',
		render: h => h(App)
	}).$mount('#app')
}

