import OnlineSimDriver from "onlinesim-js-api";

export class Free{
	constructor(){
		const apikey = '51x4C1rzt5884HV-5Ng4bY28-2wHdx5W9-UyCX5E1a-6m12dqD3Vk3FwAK';
		this.driver = new OnlineSimDriver(apikey);
		this.getter = this.driver.free()
	}
	async getCountries(){
		await this.getter.countries();
	}
	
	async getNumbers(country){
		await this.getter.numbers(country);
	}
	
	async getMessages(number){
		await this.getter.messages(number);
	}
}

export class Numbers{
	constructor(lang = 'en'){
		const apikey = '51x4C1rzt5884HV-5Ng4bY28-2wHdx5W9-UyCX5E1a-6m12dqD3Vk3FwAK';
		this.driver = new OnlineSimDriver(apikey, lang);
		this.getter  = this.driver.numbers()
	}
	
	async tariffs(){
		return await this.getter.tariffs();
	}
	
	async get(service, country, reject, extension){
		return await this.getter.get(service, country, reject, extension);
	}
	
	async state(){
		return await this.getter.state();
	}
	
	async stateOne(tzid, message_to_code){
		return await this.getter.stateOne(tzid, message_to_code);
	}
	
	async next(){
		return await this.getter.next();
	}
	
	async close(tzid){
		return await this.getter.close(tzid);
	}
	
}

export default {
	Free,
	Numbers
}