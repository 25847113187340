import Ethers from "@/js/ethers.js";
// import {API_URL} from "@/env.js";


const state = {
	ethers:false,
	provider:false,
	signer:false,
	network:false,
	contract:false
}

const getters = {
	ethers: state => state.ethers,
	provider: state => state.provider,
	signer: state => state.signer,
	network: state => state.network,
	contract: state => state.contract
}

const actions = {
	async getEthers({
		commit,
		dispatch
	}){
		let ethers = new Ethers();
		commit('ETHERS', ethers);
		await dispatch('getNetwork');
		dispatch('getProvider');
	},
	
	async getNetwork({
		getters,
		commit
	}){
		let network = await getters.ethers.getNetwork();
		console.log(JSON.parse(JSON.stringify(network)));
		commit('NETWORK', network);
	},
	
	getProvider({getters, commit, dispatch}){
		let Provider = getters.ethers.getProvider();
		commit('PROVIDER', Provider);
		dispatch('getlistAccounts');
		dispatch('getSigner');
	},
	
	async getlistAccounts({getters, commit}){
		let listAccounts = await getters.ethers.getlistAccounts();
		commit('LISTACCOUNTS', listAccounts);
		commit('ADDRESS', listAccounts[0]);
	},
	
	async getSigner({
			getters,
			commit,
			dispatch
		}, address){
		if(address === void(0)){
			address = getters.address;
		}	
		let signer = await getters.ethers.getSigner(address);
		commit('SIGNER', signer);
		dispatch('getContract', localStorage.getItem('currency_key'));
	},
	
	async getContract({
			getters,
			commit
		}, currency){
		let chainId = getters.network['chainId'],
			signer = getters.signer,
			address = getters.address;
			try{
				let contract = await getters.ethers.getContract(address, signer, chainId, currency);
				commit('CONTRACT', contract);
			} catch(e){
				console.log(e);
			}
		
	},
	
	
	addListener({
		getters
	}){
		getters.ethers.addListener();
	},
	
	removeListener({
		getters
	}){
		getters.ethers.removeListener();
	},
	
}

const mutations = {
	ETHERS(state, ethers){
		state.ethers = ethers;
	},
	PROVIDER(state, provider){
		state.provider = provider;
	},
	NETWORK(state, network){
		state.network = network;
	},
	CONTRACT(state, contract){
		state.contract = contract;
	},
	SIGNER(state, signer){
		state.signer = signer;
	}
}

export default {
	state,
	mutations,
	actions,
	getters
}