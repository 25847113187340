import {CountryInfo} from "@/js/common";
import store from "@/js/store"

export default {
	web3js(){
		if(typeof window.ethereum != 'undefined'){
			console.log("ethereum");
			localStorage.setItem('web3js', 'ethereum');
			store.dispatch('login');
			return true;
		}else if(typeof window.tronWeb != 'undefined'){
			localStorage.setItem('web3js', 'tronWeb');
			console.log("tronWeb");
			store.dispatch('login');
			console.log(window.tronLink);
			return true;
		}else{
			console.log("web3js undefined");
			localStorage.setItem('web3js', 'undefined');
			return false;
		}
	},
	
	
	/**
	 * 时间格式化
	 */
	formatdata(time, format='Y-m-d H:i:s'){
		if(time) {
			let date = new Date(time * 1000) // 时间戳为秒：10位数
			let year = date.getFullYear()
			let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
			let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
			let hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
			let minute = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
			let second = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
			
			format = format.replace("Y", `${year}`);
			format = format.replace("m", `${month}`);
			format = format.replace("d", `${day}`);
			format = format.replace("H", `${hour}`);
			format = format.replace("i", `${minute}`);
			format = format.replace("s", `${second}`);
			return format;
		} else {
			return ''
		}
	},
	/**
	 * 富文本解析
	 */
	showHtml(str){
		return str
		.replace(str ? /&(?!#?\w+;)/g : /&/g, '&amp;')
		.replace(/&lt;/g,"<")
		.replace(/&gt;/g,">")
		.replace(/&quot;/g,"\"")
		// .replace(/&#39;/g, "\'")
		.replace(/&amp;nbsp;/g,'\u3000')
	},
	
	getLangKey(value){
		let key = CountryInfo.Austria.name;
		switch(value){
			case '澳大利亚':
				key = CountryInfo.Austria.name
				break;
			case '英国':
				key = CountryInfo.Britain.name
				break;
			case '保加利亚':
				key = CountryInfo.Bulgaria.name
				break;
			case '捷克':
				key = CountryInfo.Czech.name
				break;
			case '加拿大':
				key = CountryInfo.Canada.name
				break;
			case '丹麦':
				key = CountryInfo.Denmark.name
				break;
			case '法国':
				key = CountryInfo.France.name
				break;
			case '德国':
				key = CountryInfo.Germany.name
				break;
			case '希腊':
				key = CountryInfo.Greece.name
				break;
			case '印度':
				key = CountryInfo.India.name
				break;
			case '意大利':
				key = CountryInfo.Italy.name
				break;
			case '荷兰':
				key = CountryInfo.Netherlands.name
				break;
			case '波兰':
				key = CountryInfo.Poland.name
				break;
			case '葡萄牙':
				key = CountryInfo.Portugal.name
				break;
			case '罗马尼亚':
				key = CountryInfo.Romania.name
				break;
			case '俄罗斯':
				key = CountryInfo.Russia.name
				break;
			case '西班牙':
				key = CountryInfo.Spain.name
				break;
			case '瑞士':
				key = CountryInfo.Sweden.name
				break;
			case '中国台湾':
				key = CountryInfo.Taiwan.name
				break;
			case '泰国':
				key = CountryInfo.Thailand.name
				break;
			case '乌克兰':
				key = CountryInfo.Ukraine.name
				break;
			case '美国':
				key = CountryInfo.USA.name
				break;
			case '越南':
				key = CountryInfo.Vietnam.name
				break;
				
			default:
			
		}
		return key;
	},
	
	padLeft(total, pad){
		return (Array(total).join(pad || 0) + pad).slice(-total);
	}
	
}