<template>
	<v-app>
	<!-- 头部 -->
	<CommonHead/>
	
	<v-main>
		<router-view></router-view>
	</v-main>
	<CommonFooter/>
	</v-app>
</template>

<script>
import CommonHead from '@/components/CommonHead';
import CommonFooter from '@/components/CommonFooter';
import Tron from "@/js/tron"

export default {
	name: 'App',

	components: {
		CommonHead,
		CommonFooter
	},

	data: () => ({
	//
	}),
	beforeCreate(){
		let that = this;
		
		let tron = new Tron();
		tron.addListener();
		
		this.$router.onReady(() => {
			if(that.$route.query.currency){
				localStorage.setItem('currency_key', that.$route.query.currency);
			}else if(!localStorage.getItem('currency_key')){
				localStorage.setItem('currency_key', 'usdt');
			}
		})
		var obj = setInterval(async ()=>{
			if (that.$tools.web3js()) {
				clearInterval(obj)
			}
		}, 10)
		
		this.$store.dispatch('getUserInfo');
		this.$store.dispatch("orderList");
	},
	mounted(){
		
	},
	beforeDestroy(){
		if(typeof window.ethereum != 'undefined'){
			this.$store.dispatch('removeListener');
		}
	}
};
</script>
<style scoped>
	/deep/ .v-btn {
		text-transform: none;
	}
	/deep/ .v-btn:not(.v-btn--round).v-size--default {
		height: 44px;
		padding: 0 24px;
	}
</style>