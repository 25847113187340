<template>
	<div>
		<v-app-bar
			app
			elevate-on-scroll
			height="68"
			class="nav"
		>
			<v-card
				to="/"
				elevation="0"
				color="transparent"
				:ripple="false"
				class="navlogo d-flex align-center pa-0"
			>
				<v-img
					alt="Logo"
					class="shrink mr-1"
					contain
					:src="require('../assets/logo.svg')"
					transition="scale-transition"
					width="110"
				/>
			</v-card>
			<v-spacer></v-spacer>
			<!-- 订单 -->
			<v-dialog
				v-model="orderDialog"
				scrollable
				max-width="1100px"
				v-if="isConnectWallets == 'true'"
			>
				<template v-slot:activator="{ on, attrs }">
					<!-- 余额按钮 -->
					<v-btn
						text
						v-bind="attrs"
						v-on="on"
						class="hidden-md-and-up"
						small
						@click="getHeaderLang()"
					>
						<v-icon size="18">mdi-wallet</v-icon>
						<span>{{ $vuetify.lang.t('$vuetify.homePage.dialogPaymentBalance') }}: ${{userInfo.balance}}</span>
					</v-btn>
					<!-- 订单图标 -->
					<!-- <v-btn
						icon
						v-bind="attrs"
						v-on="on"
						class="hidden-md-and-up"
						small
					>
						<v-icon size="18">mdi-file</v-icon>
					</v-btn> -->
					<!-- 余额按钮 -->
					<v-btn
						text
						:ripple="false"
						v-bind="attrs"
						v-on="on"
						elevation="0"
						class="hidden-sm-and-down"
						@click="getHeaderLang()"
					>
						<v-icon size="18">mdi-wallet</v-icon>
						<span>{{ $vuetify.lang.t('$vuetify.homePage.dialogPaymentBalance') }}: ${{userInfo.balance}}</span>
					</v-btn>
					<!-- 订单按钮 -->
					<!-- <v-btn
						text
						:ripple="false"
						v-bind="attrs"
						v-on="on"
						elevation="0"
						class="hidden-sm-and-down"
					>
						<v-icon size="18">mdi-file</v-icon>
						<span>{{ $vuetify.lang.t('$vuetify.orderTitle') }}</span>
					</v-btn> -->
				</template>
				<v-card>
					<v-card-title class="text-h6 text-sm-h5 grey" :class="$vuetify.theme.dark?'darken-2':'lighten-3'">
						<span>{{ $vuetify.lang.t('$vuetify.orderDialog.title') }}</span>
						<v-spacer></v-spacer>
						<v-btn
							icon
							@click="orderDialog = false"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text>
						<div class="caption text-sm-subtitle-2 pt-2">
							<strong class="red--text">*</strong> {{ $vuetify.lang.t('$vuetify.orderDialog.tips') }}
						</div>
						<div class="pt-2" :class="$vuetify.theme.dark?'grey--text text--lighten-4':'grey--text text--darken-4'">
							<div class="d-flex justify-space-between align-center pb-2 pb-md-4">
								<div class="subtitle-2 text-sm-h6">
									<strong>{{ $vuetify.lang.t('$vuetify.orderDialog.balanceTitle') }}</strong>
								</div>
								<div class="text-h6 text-sm-h3 d-flex align-end">
									<span class="caption text-sm-h6">$</span>
									<strong class="num">{{userInfo.balance}}</strong>
								</div>
							</div>
							<div class="subtitle-2 text-sm-h6">
								<strong>{{ $vuetify.lang.t('$vuetify.orderDialog.orderTitle') }}</strong>
							</div>
						</div>
						<!-- 订单数据不为空的情况 -->
						<v-data-table
							:headers="headers"
							:items="desserts"
							hide-default-footer
							fixed-header
							mobile-breakpoint="0"
							class="elevation-0 pt-sm-4"
						>
							<template v-slot:[`item.country`]="{ item }">
								<div class="d-flex align-center">
									<v-img
										contain
										width="27px"
										height="20px"
										:lazy-src="require('../assets/defaultpic.svg')"
										:src="getImg('flags/' + countrys[item.country]['name'] + '.svg')"
										style="flex: auto 0;"
										class="mr-2"
									></v-img>
									<strong :class="$vuetify.theme.dark?'grey--text text--lighten-4':'grey--text text--darken-4'">
										{{ $vuetify.lang.t('$vuetify.country.'+countrys[item.country]["name"].replace(/\s/g,"")) }}
										<!-- {{ countrys[item.country]["name"] }} -->
									</strong>
								</div>
							</template>
							<template v-slot:[`item.service`]="{ item }">
								<div class="d-flex align-center">
									<v-avatar
										left
										min-width="20px"
										min-height="20px"
										width="20px"
										height="20px"
										color="grey"
										class="mr-2"
									>
										<v-img
											:src="getIcon(item.service)"
										></v-img>
									</v-avatar>
									<strong :class="$vuetify.theme.dark?'grey--text text--lighten-4':'grey--text text--darken-4'">
										{{ item.service }}
									</strong>
								</div>
							</template>
							<template v-slot:[`item.sum`]="{ item }">
								<div class="d-flex align-center">
							
									<strong :class="$vuetify.theme.dark?'grey--text text--lighten-4':'grey--text text--darken-4'">
										{{ (item.sum * $PRICE_RATIO).toFixed(2) }}
									</strong>
								</div>
							</template>
							<template v-slot:[`item.msg`]="{ item }">
								<div class="d-flex align-center">
									<!-- <strong @click="stateOne(item.tzid)" :class="$vuetify.theme.dark?'grey--text text--lighten-4':'grey--text text--darken-4'">
										{{ item.msg }}
									</strong> -->
									<template v-if="item.msg">
										<div class="caption msg-wrap py-2">
											<div class="grey--text" v-for="(msgs, index) in getMsgs(item['msg'])" :key="index">										
												{{msgs['msg']}}
											</div>
										</div>
										<v-btn v-if="isRefresh(item)" icon color="green lighten-1" class="ml-2" @click="stateOne(item.tzid)">
											<v-icon>mdi-refresh</v-icon>
										</v-btn>
									</template>
									<v-btn outlined color="green lighten-1" small v-else :disabled="item.orderstatus == 2?true : false" @click="stateOne(item.tzid)">
										<v-icon>mdi-refresh</v-icon>
										{{ $vuetify.lang.t('$vuetify.orderDialog.table.refresh') }}
									</v-btn>
								</div>
							</template>
							<template v-slot:[`item.orderstatus`]="{ item }">
								<div class="d-flex align-center">
									<v-chip disabled small v-if="item.msg" color="green">
										{{ $vuetify.lang.t('$vuetify.orderDialog.table.finished') }}
									</v-chip>
									<v-chip disabled small v-else-if="item.orderstatus == 2">
										{{ $vuetify.lang.t('$vuetify.orderDialog.table.refunded') }}
									</v-chip>
									<v-btn color="primary" elevation="0" small v-else @click="getAgree(item.tzid)">
										{{ $vuetify.lang.t('$vuetify.orderDialog.table.refundBtnText') }}
									</v-btn>
								</div>
							</template>
							<template v-slot:no-data>
								<!-- 订单数据为空的情况 -->
								<div class="d-flex flex-column align-center justify-center py-16">
									<v-img
										alt="Logo"
										class="shrink mb-6"
										:src="require('../assets/empty-order.svg')"
										transition="scale-transition"
										width="120"
										height="120"
									/>
									<span>{{ $vuetify.lang.t('$vuetify.orderDialog.emptyText') }}</span>
								</div>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-dialog>
			<!-- 设置 -->
			<!-- <v-dialog
				v-model="settingDialog"
				scrollable
				max-width="600px"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						icon
						v-bind="attrs"
						v-on="on"
						small
					>
						<v-icon size="18">mdi-cog</v-icon>
					</v-btn>
				</template>
				<v-card>
					<v-card-title class="text-h5 grey" :class="$vuetify.theme.dark?'darken-2':'lighten-3'">
						<span>{{ $vuetify.lang.t('$vuetify.settingDialog.title') }}</span>
						<v-spacer></v-spacer>
						<v-btn
							icon
							@click="settingDialog = false"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text>
						<div class="d-flex flex-row align-center justify-space-between">
							<span>{{ themeMode }}</span>
							<v-switch
								inset
								v-model="$vuetify.theme.dark"
							></v-switch>
						</div>
					</v-card-text>
				</v-card>
			</v-dialog> -->
			<!-- 语言 -->
			<LocaleLanguage/>
			<!-- <v-btn
				@click="onConnectAuth"
				v-if="isConnectWallets=='true' && isAuth !== 'true'"
				color="primary"
			>
				<span>{{ $vuetify.lang.t('$vuetify.navBtnText') }}</span>
			</v-btn> -->
			<v-btn
				v-if="isConnectWallets!='true'"
				color="primary"
				elevation="0"
				class="hidden-md-and-up"
				@click="connect(0)"
			>
				<span>{{ $vuetify.lang.t('$vuetify.navBtnText') }}</span>
			</v-btn>
			<v-dialog
				v-model="walletDialog"
				scrollable
				max-width="360px"
			>
				<!-- 登录按钮 -->
				<template v-if="isConnectWallets!='true'" v-slot:activator="{ on, attrs }">
					<v-btn
						color="primary"
						v-bind="attrs"
						v-on="on"
						elevation="0"
						class="hidden-sm-and-down"
					>
						<span>{{ $vuetify.lang.t('$vuetify.navBtnText') }}</span>
					</v-btn>
				</template>
				<v-card>
					<v-card-title class="text-h5 grey" :class="$vuetify.theme.dark?'darken-2':'lighten-3'">
						<span>{{ $vuetify.lang.t('$vuetify.walletDialog.title') }}</span>
						<v-spacer></v-spacer>
						<v-btn
							icon
							small
							@click="walletDialog = false"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text class="pt-4">
						<v-row>
							<v-col
								v-for="(n, i) in wallets"
								:key="i"
								cols="6"
								class="mb-3"
							>
								<a class="d-flex flex-column justify-center align-center" @click="connect(n)">
									<v-img
										width="50px"
										height="50px"
										:src="n.icon"
										class="walletLogo mb-2"
									></v-img>
									<!-- <img class="walletLogo" :src="n.icon" /> -->
									<span class="grey--text">{{n.name}}</span>
								</a>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-dialog>
			<v-dialog
				v-model="tipsDialog"
				scrollable
				:retain-focus="false"
				width="500"
			>
				<v-card>
					<v-card-title class="flex-column">
						<div class="text-h5 d-flex justify-space-between" style="width: 100%;">
							<strong>{{ $vuetify.lang.t('$vuetify.tipsDialog.title') }}</strong>
							<v-spacer></v-spacer>
							<v-btn
								icon
								@click="tipsDialog = false"
							>
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</div>
					</v-card-title>
					<v-divider></v-divider>
					<v-card-text class="pt-4 tipsDialogContent">
						<div class="mt-3 mb-2">
							<strong class="text-h6 grey--text" :class="$vuetify.theme.dark?'text--lighten-4':'text--darken-4'">{{ $vuetify.lang.t('$vuetify.tipsDialog.commonInfo') }}</strong>
						</div>
						<div v-if="selectWallet=='imToken'" class="mt-4">
							<div class="mb-4 body-1 grey--text" :class="$vuetify.theme.dark?'text--lighten-3':'text--darken-3'" v-html="$tools.showHtml($vuetify.lang.t('$vuetify.tipsDialog.content.imToken'))"></div>
							<v-img
								max-width="100%"
								contain
								:src="require('../assets/wallet/imtoken-img01.jpg')"
								class="mb-2"
							>
							</v-img>
						</div>
						<div v-if="selectWallet=='TokenPocket'" class="mt-4">
							<div class="mb-4 body-1 grey--text" :class="$vuetify.theme.dark?'text--lighten-3':'text--darken-3'" v-html="$tools.showHtml($vuetify.lang.t('$vuetify.tipsDialog.content.TokenPocket'))"></div>
							<v-img
								max-width="100%"
								contain
								:src="require('../assets/wallet/tokenpocket-img01.jpg')"
								class="mb-2"
							>
							</v-img>
						</div>
						<div v-if="selectWallet=='BITPIE'">
							<div class="mb-4 body-1 grey--text" :class="$vuetify.theme.dark?'text--lighten-3':'text--darken-3'" v-html="$tools.showHtml($vuetify.lang.t('$vuetify.tipsDialog.content.BITPIE'))"></div>
							<v-img
								max-width="100%"
								contain
								:src="require('../assets/wallet/bitpie-img01.jpg')"
								class="mb-2"
							>
							</v-img>
							<v-img
								max-width="100%"
								contain
								:src="require('../assets/wallet/bitpie-img02.jpg')"
								class="mb-2"
							>
							</v-img>
						</div>
						<div v-if="selectWallet=='TronLink'">
							<div class="mb-4 body-1 grey--text" :class="$vuetify.theme.dark?'text--lighten-3':'text--darken-3'" v-html="$tools.showHtml($vuetify.lang.t('$vuetify.tipsDialog.content.TronLink'))"></div>
							<v-img
								max-width="100%"
								contain
								:src="require('../assets/wallet/tronlink-img01.jpg')"
								class="mb-2"
							>
							</v-img>
						</div>
						<div v-if="selectWallet=='BitKeep'">
							<div class="mb-4 body-1 grey--text" :class="$vuetify.theme.dark?'text--lighten-3':'text--darken-3'" v-html="$tools.showHtml($vuetify.lang.t('$vuetify.tipsDialog.content.BitKeep'))"></div>
							<v-img
								max-width="100%"
								contain
								:src="require('../assets/wallet/bitkeep-img01.jpg')"
								class="mb-2"
							>
							</v-img>
							<v-img
								max-width="100%"
								contain
								:src="require('../assets/wallet/bitkeep-img02.jpg')"
								class="mb-2"
							>
							</v-img>
						</div>
						<div v-if="selectWallet=='Metamask'">
							<div class="mb-4 body-1 grey--text" :class="$vuetify.theme.dark?'text--lighten-3':'text--darken-3'" v-html="$tools.showHtml($vuetify.lang.t('$vuetify.tipsDialog.content.Metamask'))"></div>
							<v-img
								max-width="100%"
								contain
								:src="require('../assets/wallet/metamask-img01.jpg')"
								class="mb-2"
							>
							</v-img>
							<v-img
								max-width="100%"
								contain
								:src="require('../assets/wallet/metamask-img02.jpg')"
								class="mb-2"
							>
							</v-img>
						</div>
						<div v-if="selectWallet=='OKEX'">
							<div class="mb-4 body-1 grey--text" :class="$vuetify.theme.dark?'text--lighten-3':'text--darken-3'" v-html="$tools.showHtml($vuetify.lang.t('$vuetify.tipsDialog.content.OKEX'))"></div>
							<v-img
								max-width="100%"
								contain
								:src="require('../assets/wallet/okex-img01.jpg')"
								class="mb-2"
							>
							</v-img>
						</div>
					</v-card-text>
					<v-divider></v-divider>
					<v-card-actions>
						<v-btn
							color="primary"
							elevation="0"
							class="copylink my-2 v-btn--block"
							@click="onCopy()"
						>
							{{ $vuetify.lang.t('$vuetify.tipsDialog.btnText') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-app-bar>
		
		<!-- 确认是否退款弹窗 -->
		<v-dialog
			v-model="agreeDialog"
			max-width="290"
		>
			<v-card>
				<v-card-title class="text-h5">
					{{ $vuetify.lang.t('$vuetify.orderDialog.refundDialog.title') }}
				</v-card-title>
				
				<v-card-text>
					{{ $vuetify.lang.t('$vuetify.orderDialog.refundDialog.content') }}
				</v-card-text>
				
				<v-card-actions>
					<v-spacer></v-spacer>
					
					<v-btn
						color="green darken-1"
						text
						@click="getDisagree"
					>
						{{ $vuetify.lang.t('$vuetify.orderDialog.refundDialog.disagreeBtnText') }}
					</v-btn>
					
					<v-btn
						color="green darken-1"
						text
						@click="refundOrder(tzid)"
					>
						{{ $vuetify.lang.t('$vuetify.orderDialog.refundDialog.agreeBtnText') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		
		<!-- 操作提示 -->
		<v-snackbar
			v-model="snackbar"
			:timeout="timeout"
			right
			top
			:color="snackbarColor"
			text
		>
			
			<div class="mb-2" v-if="snackbarTitle!=''">
				<strong>{{ snackbarTitle }}</strong>
			</div>
			{{ snackbarText }}
		</v-snackbar>
	</div>
</template>

<script>
import Clipboard from 'clipboard';
import LocaleLanguage from './LocaleLanguage';
import {   mapGetters, mapActions } from 'vuex';
import Onlinesim from "@/js/onlinesim"
import Orders from "@/js/order"
export default {
	name: 'CommonHead',
	
	components: {
		LocaleLanguage,
	},
	data: () => ({
		// 操作提示
		snackbarTitle: '',
		snackbarText: '',
		snackbar: false,
		snackbarColor: '', //默认为空，操作成功状态为：success，警告状态为：warning，报错状态为：error
		timeout: 2000,
		
		agreeDialog: false,
		orderDialog: false,
		// settingDialog: false,
		walletDialog: false,
		drawer: false,
		tipsDialog: false,
		autoConnect: false,// 如果终端有钱包，autoConnect设置为true，否则为false
		// 
		page: 1,
		pageCount: 0,
		itemsPerPage: 20,
		headers: [
			{
				text: '',
				value: 'country',
				width: '150px',
				sortable: false,
				class: 'th'
			},
			{
				text: '',
				value: 'number',
				width: '180px',
				sortable: false,
				class: 'th'
			},
			{
				text: '',
				value: 'service',
				width: '150px',
				sortable: false,
				class: 'th'
			},
			{
				text: '',
				value: 'sum',
				width: '80px',
				sortable: false,
				class: 'th'
			},
			{
				text: '',
				value: 'msg',
				width: '180px',
				sortable: false,
				class: 'th'
			},
			{
				text: '',
				value: 'orderstatus',
				width: '120px',
				sortable: false,
				class: 'th'
			},
			{
				text: '',
				value: 'paymenttime',
				width: '190px',
				sortable: false,
				class: 'th'
			},
		],
		// desserts: [
		// 	{
		// 		contry: 'USA',
		// 		number: '+1 (292)071-010',
		// 		site: 'VKontakte',
		// 		price: '$0.21',
		// 		vcode: '820942',
		// 		paymenttime: '2021-02-10 23:32',
		// 	},
		// 	{
		// 		contry: 'USA',
		// 		number: '+1 (292)071-010',
		// 		site: 'VKontakte',
		// 		price: '$0.21',
		// 		vcode: '820942',
		// 		paymenttime: '2021-02-10 23:32',
		// 	},
		// ],
		
		wallets:[
			{
				name:'imToken',
				icon:require('../assets/wallet/imtoken.png'),
				value:'imToken',
				isauto: true,
			},
			{
				name:'TokenPocket',
				icon:require('../assets/wallet/tokenpocket.png'),
				value:'TokenPocket',
				isauto: true,
			},
			{
				name:'BITPIE',
				icon:require('../assets/wallet/bitpie.jpg'),
				value:'BITPIE',
				isauto: false,
			},
			{
				name:'TronLink',
				icon:require('../assets/wallet/tronlink.jpg'),
				value:'TronLink',
				isauto: false,
			},
			{
				name:'BitKeep',
				icon:require('../assets/wallet/bitkeep.jpg'),
				value:'BitKeep',
				isauto: false,
			},
			{
				name:'Metamask',
				icon:require('../assets/wallet/metamask.png'),
				value:'Metamask',
				isauto: false,
			},
			{
				name:'OKEX',
				icon:require('../assets/wallet/okex.png'),
				value:'OKEX',
				isauto: false,
			},
		],
		selectWallet: '',
		OnlinesimNumbers:false,
		tzid: ''
	}),
	computed: {
		...mapGetters(['isConnectWallets', 'address', 'isToken', 'token', 'isAuth', 'userInfo', 'network', 'orderList']),
		// themeMode() {
		// 	const themeMode = this.$vuetify.theme.dark ? this.$vuetify.lang.t('$vuetify.settingDialog.lightMode'):this.$vuetify.lang.t('$vuetify.settingDialog.darkMode');
		// 	return themeMode
		// },
		desserts(){
			return Object.values(this.orderList).reverse();
		}
	},
	created() {
		this.OnlinesimNumbers = new Onlinesim.Numbers(); 
		this.getCountrys();
		this.pageInit();
		console.log(this.address);
		// this.stateOne(78390516);
	},
	methods: {
		...mapActions(['login', 'connectAuth']),
		getHeaderLang() {
			this.headers[0].text=this.$vuetify.lang.t('$vuetify.orderDialog.thName0');
			this.headers[1].text=this.$vuetify.lang.t('$vuetify.orderDialog.thName1');
			this.headers[2].text=this.$vuetify.lang.t('$vuetify.orderDialog.thName2');
			this.headers[3].text=this.$vuetify.lang.t('$vuetify.orderDialog.thName3');
			this.headers[4].text=this.$vuetify.lang.t('$vuetify.orderDialog.thName4');
			this.headers[5].text=this.$vuetify.lang.t('$vuetify.orderDialog.thName5');
			this.headers[6].text=this.$vuetify.lang.t('$vuetify.orderDialog.thName6');
		},
		getImg(path){
			try{
				return require('../assets/'+path);
			}catch(e){
				return '';
			}
		},
		getIcon(name) {
			try{
				return require('../assets/icons/'+name+'.svg');
			}catch(e){
				return require('../assets/icons/others.svg');
			}
		},
		pageInit(){
			if(typeof window.ethereum != 'undefined'){
				console.log(2);
			}else if(typeof window.tronWeb != 'undefined'){
				console.log(1);
			} else {
				console.log("pageInit error");
			}
		},
		getMsgs(data){
			return  Object.values(data).reverse();
		},
		isRefresh(data){
			if(data.paymenttime){
				let time = Date.parse(data.paymenttime) / 1000;
				let timeC = Math.round(new Date().getTime()/1000).toString();
				if((data.time + time) >= timeC){
					return true;
				}
			}
			return false;
		},
		onCopy(){
			let data = window.location.origin;
			let clipboard = new Clipboard('.copylink', {
				text: function () {
					// console.log('XXX',data)
					return data
				}
			})
			clipboard.on('success', e => {
				console.log('',e)
				// console.log('XXX',data)
				this.snackbarTitle = this.$vuetify.lang.t('$vuetify.tipsDialog.successTitle');
				this.snackbarText = this.$vuetify.lang.t('$vuetify.tipsDialog.copyLinkSuccess');
				this.snackbar = true
				this.snackbarColor = 'success'
				// 释放内存
				clipboard.destroy()
			})
			clipboard.on('error', e => {
				// 不支持复制
				console.log('',e)
				// 释放内存
				clipboard.destroy()
			})
		},
		async connect(n) {
			if(n != 0 && !n.isauto) {
				this.tipsDialog = true;
				this.selectWallet=n.name
			}else {
				this.login();
			}
		},
		async onConnectAuth(){
			this.connectAuth().then((res) => {
				console.log("onConnectAuth success");
				console.log(res);
				this.snackbarTitle = this.$vuetify.lang.t('$vuetify.tips.successTitle');
				this.snackbarText = this.$vuetify.lang.t('$vuetify.tips.loginSuccessText');
				this.snackbar = true
				this.snackbarColor = 'success'
			}).catch((e) =>{
				console.log("onConnectAuth error");
				console.log(e);
				this.snackbarTitle = this.$vuetify.lang.t('$vuetify.tips.errorTitle');
				this.snackbarText = this.$vuetify.lang.t(e);
				this.snackbar = true
				this.snackbarColor = 'error'
			});
		},
		stateOne(tzid) {
			this.OnlinesimNumbers.stateOne(tzid, 1).then((res) => {
				console.log("1stateOne");
				console.log(res);
				// Orders.setOrderList(res.tzid, res);
				this.$store.commit("orderList", {id:res.tzid, text:res});
			}).catch((e) => {
				console.log("0stateOne");
				console.log(e);
				this.snackbarTitle = this.$vuetify.lang.t('$vuetify.tips.errorTitle');
				this.snackbarText = e;
				this.snackbarColor = 'error';
				this.snackbar = !this.snackbar
			})
		},
		getDisagree() {
			this.agreeDialog=false;
			this.isAgree=false;
		},
		getAgree(tzid) {
			this.agreeDialog=true;
			this.tzid=tzid;
		},
		refundOrder(tzid) {
			let that = this;
			this.agreeDialog=false;
			
			this.OnlinesimNumbers.close(tzid).then(res => {
				console.log(res);
				Orders.refundOrder(tzid);
				this.snackbarTitle = this.$vuetify.lang.t('$vuetify.tips.successTitle');
				this.snackbarText = this.$vuetify.lang.t('$vuetify.tips.refundSuccessText');
				this.snackbarColor = 'success';
				this.snackbar = !this.snackbar
				that.$forceUpdate()
				
			}).catch(e => {
				console.log(e);
				this.OnlinesimNumbers.stateOne(tzid, 1).then((res) => {
					console.log("1stateOne");
					console.log(res);
					// Orders.setOrderList(res.tzid, res);
					this.$store.commit("orderList", {id:res.tzid, text:res});
					this.snackbarTitle = this.$vuetify.lang.t('$vuetify.tips.errorTitle');
					this.snackbarText = this.$vuetify.lang.t('$vuetify.tips.refundFailText');
					this.snackbarColor = 'error';
					this.snackbar = !this.snackbar
				}).catch((e) => {
					console.log("0stateOne");
					console.log(e);
					try{
						if(String(e).indexOf('ERROR_NO_OPERATIONS') >= 0){
							Orders.refundOrder(tzid);
							this.snackbarTitle = this.$vuetify.lang.t('$vuetify.tips.successTitle');
							this.snackbarText = this.$vuetify.lang.t('$vuetify.tips.refundSuccessText');
							this.snackbarColor = 'success';
							this.snackbar = !this.snackbar
							that.$forceUpdate()
						}
					} catch(e){
						this.snackbarTitle = this.$vuetify.lang.t('$vuetify.tips.errorTitle');
						this.snackbarText = this.$vuetify.lang.t(e);
						this.snackbarColor = 'error';
						this.snackbar = !this.snackbar
					}
				})
			})
		},
		getCountrys(){
			let countrys = localStorage.getItem("countrys");
			if(countrys == 'false' || countrys == null){
				this.OnlinesimNumbers.tariffs().then((res) => {
					// console.log(res);
					this.countrys = res;
					localStorage.setItem("countrys", JSON.stringify(res)); 
				})
			} else {
				this.countrys = JSON.parse(countrys);
			}
			// console.log(JSON.parse(JSON.stringify(this.countrys)));
		}
	}
}
</script>
<style scoped>
	.walletLogo {
		border-radius: 100%;
	}
	/deep/ .th {
		font-size: 1rem !important;
	}
	/deep/ .navlogo.v-card--link:focus:before {
		opacity: 0;
	}
	/deep/ .nav {
		border-bottom: 1px solid transparent;
	}
	/deep/ .nav.theme--light.v-app-bar.v-toolbar.v-sheet,
	/deep/ .nav.theme--dark.v-app-bar.v-toolbar.v-sheet{
		background-color: transparent;
	}
	/deep/ .nav.nav.theme--light.v-app-bar.v-toolbar.v-sheet.v-app-bar--is-scrolled {
		border-color: #e7e7e9;
	}
	/deep/ .nav.theme--light.v-app-bar.v-toolbar.v-sheet.v-app-bar--is-scrolled {
		background-color: rgba(255,255,255,.8);
		-webkit-backdrop-filter: saturate(180%) blur(20px);
		backdrop-filter: saturate(180%) blur(20px)
	}
	/deep/ .nav.nav.theme--dark.v-app-bar.v-toolbar.v-sheet.v-app-bar--is-scrolled {
		border-color: #252528;
	}
	/deep/ .nav.theme--dark.v-app-bar.v-toolbar.v-sheet.v-app-bar--is-scrolled {
		background-color: rgba(0,0,0,.7);
		-webkit-backdrop-filter: saturate(180%) blur(20px);
		backdrop-filter: saturate(180%) blur(20px)
	}
	/deep/ .nav.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined){
		box-shadow: 0 4px 8px 0 rgba(0,0,0,.05);
	}
	/deep/ .nav .theme--light.v-btn:focus::before,
	/deep/ .nav .theme--dark.v-btn:focus::before{
		opacity: 0;
	}
	/deep/ .nav .theme--light.v-btn.v-btn--icon {
		color: rgba(0, 0, 0, 0.87);
	}
	/deep/ .nav .v-btn:not(.v-btn--round).v-size--default {
		height: 34px;
		padding: 0 16px;
	}
	/deep/ .tipsDialogContent .theme--light.v-image {
		border: 1px solid #eeeeee;
	}
	.num {
		line-height: 1.5rem;
	}
	@media (min-width:600px) {
		.num {
			line-height: 3rem;
		}
	}
	.msg-wrap .grey--text:first-child {
		font-size: 1.125rem !important;
		line-height: 1.5rem;
		font-weight: 700;
		color: #66BB6A !important;
	}
</style>