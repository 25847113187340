<template>
	<div>
		<v-footer
			dark
			padless
		>
			<v-container class="pt-10 pb-2 pb-lg-10">
				<v-row
					class="mb-4"
					align="center"
					justify="center"
					justify-sm="space-between"
				>
					<v-col
						cols="12"
						sm="6"
						class="d-flex align-sm-center justify-center d-sm-block"
					>
						<v-img
							class="shrink mr-1"
							contain
							:src="require('../assets/footer-logo.svg')"
							transition="scale-transition"
							width="130"
						/>
					</v-col>
					<v-col
						cols="12"
						sm="6"
						class="d-sm-flex justify-sm-end"
					>
						<div class="d-flex align-center justify-center">
							<span class="mr-3">{{ themeMode }}</span>
							<v-switch
								inset
								v-model="$vuetify.theme.dark"
							>
							</v-switch>
						</div>
					</v-col>
				</v-row>
				<v-row
					dense
					align="center"
				>
					<v-col
						cols="12"
						sm="6"
						lg="3"
						class="caption text-center text-sm-left"
						order="4"
						order-sm="1"
						order-lg="1"
					>
						© {{ new Date().getFullYear() }} — {{$vuetify.lang.t('$vuetify.copyright')}}
					</v-col>
					<v-col
						cols="12"
						lg="6"
						order="2"
						order-sm="4"
						order-lg="2"
						class="pt-3 pt-sm-0"
					>
						<div class="d-flex flex-column flex-sm-row justify-center justify-sm-start justify-lg-center">
							<v-btn
								color="white"
								text
								dense
								:ripple="false"
								href="/offer"
								class="footbtn pl-4 pl-sm-0 pl-lg-4"
							>
								{{$vuetify.lang.t('$vuetify.footerLink.link0')}}
							</v-btn>
							<v-btn
								color="white"
								text
								dense
								:ripple="false"
								href="/privacy"
								class="footbtn"
							>
								{{$vuetify.lang.t('$vuetify.footerLink.link1')}}
							</v-btn>
						</div>
					</v-col>
					<v-col
						cols="12"
						sm="6"
						lg="3"
						order="1"
						order-sm="2"
						order-lg="3"
					>
						<div class="d-flex align-center justify-center justify-sm-end flex-wrap flex-sm-nowrap">
							<v-img
								class="shrink ml-2"
								contain
								:src="require('../assets/foot/coin-2.png')"
								transition="scale-transition"
								height="25"
							/>
							<v-img
								class="shrink ml-2"
								contain
								:src="require('../assets/foot/coin-3.png')"
								transition="scale-transition"
								height="25"
							/>
							<v-img
								class="shrink ml-2"
								contain
								:src="require('../assets/foot/coin-4.png')"
								transition="scale-transition"
								height="25"
							/>
							<v-img
								class="shrink ml-2"
								contain
								:src="require('../assets/foot/coin-5.png')"
								transition="scale-transition"
								height="25"
							/>
							<v-img
								class="shrink ml-2"
								contain
								:src="require('../assets/foot/coin-1.png')"
								transition="scale-transition"
								height="25"
							/>
						</div>
					</v-col>
					<v-col
						cols="12"
						order="3"
						order-sm="3"
						class="pt-2 pt-sm-4 hidden-lg-and-up"
					>
						<v-divider></v-divider>
					</v-col>
				</v-row>
			</v-container>
		</v-footer>
		<!-- 返回顶部 -->
		<transition name="fade">
			<v-btn
				v-show="backTopShow"
				class="goTop"
				fab
				:ripple="false"
				elevation="0"
				@click="$vuetify.goTo(0, 'duration')"
			>
				<v-icon>mdi-arrow-up</v-icon>
			</v-btn>
		</transition>
	</div>
</template>

<script>
export default {
	name: 'CommonFooter',
	
	components: {
	},
	data: () => ({
		backTopShow: false
	}),
	computed: {
		themeMode() {
			const themeMode = this.$vuetify.theme.dark ? this.$vuetify.lang.t('$vuetify.settingDialog.darkMode'):this.$vuetify.lang.t('$vuetify.settingDialog.lightMode');
			return themeMode
		},
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll)
	},
	created() {
	},
	methods: {
		handleScroll() {
			if (document.documentElement.scrollTop + document.body.scrollTop > 500) {
				this.backTopShow = true
			}
			else {
				this.backTopShow = false
			}
		}
	}
}
</script>
<style scoped>
	.walletLogo {
		width: 50px;
		height: 50px;
		margin-bottom: 5px;
		border-radius: 100%;
	}
	/deep/ .footbtn {
		text-transform: inherit;
		letter-spacing: 0;
		font-weight: normal;
		opacity: .8;
	}
	/deep/ .footbtn.theme--dark.v-btn:hover::before,
	/deep/ .footbtn.theme--dark.v-btn:focus::before,
	/deep/ .goTop.theme--dark.v-btn:hover::before,
	/deep/ .goTop.theme--dark.v-btn:focus::before,
	/deep/ .goTop.theme--light.v-btn:hover::before,
	/deep/ .goTop.theme--light.v-btn:focus::before{
		opacity: 0;
	}
	/deep/ .footbtn:hover {
		opacity: 1;
		font-weight: normal;
	}
	/deep/ .goTop .theme--light.v-icon {
		color: #78909C;
	}
	/deep/ .goTop:hover .theme--light.v-icon {
		color: #263238;
	}
	/deep/ .goTop .theme--dark.v-icon {
		color: #ffffff;
		opacity: .6;
	}
	/deep/ .goTop:hover .theme--dark.v-icon {
		opacity: 1;
	}
	
	.goTop {
		position: fixed;
		right: 15px;
		bottom: 70px;
		box-shadow: 0 5px 20px 0 rgba(0,0,0,.1)!important;
		background: hsla(0,0%,100%,.3);
		/* width: 40px;
		height: 40px; */
		border-radius: 50%;
		cursor: pointer;
		z-index: 10001;
		/* transition: background .3s,transform .3s,opacity .3s; */
		/* padding: 5px; */
		/* opacity: 0; */
		/* transform: translateY(10px); */
	}
	.fade-enter-active, .fade-leave-active {
		transition: opacity .5s;
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}
</style>