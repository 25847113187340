export const CountryInfo = {
	// Austria: {
	// 	key: 'Austria',
	// 	name: '$vuetify.country.Austria',// 澳大利亚
	// 	img: require('../assets/flags/Austria.svg'),
	// 	code: '+43'
	// },
	// Britain: {
	// 	key: 'Britain',
	// 	name: '$vuetify.country.Britain',// 英国
	// 	img: require('../assets/flags/Britain.svg'),
	// 	code: '+44'
	// },
	// Bulgaria: {
	// 	key: 'Bulgaria',
	// 	name: '$vuetify.country.Bulgaria',// 保加利亚
	// 	img: require('../assets/flags/Bulgaria.svg'),
	// 	code: '+359'
	// },
	// Canada: {
	// 	key: 'Canada',
	// 	name: '$vuetify.country.Canada',// 加拿大
	// 	img: require('../assets/flags/Canada.svg'),
	// 	code: '+1'
	// },
	// Czech: {
	// 	key: 'Czech',
	// 	name: '$vuetify.country.Czech',// 捷克
	// 	img: require('../assets/flags/Czech.svg'),
	// 	code: '+420'
	// },
	// Denmark: {
	// 	key: 'Denmark',
	// 	name: '$vuetify.country.Denmark',// 丹麦
	// 	img: require('../assets/flags/Denmark.svg'),
	// 	code: '+45'
	// },
	// France: {
	// 	key: 'France',
	// 	name: '$vuetify.country.France',// 法国
	// 	img: require('../assets/flags/France.svg'),
	// 	code: '+33'
	// },
	// Germany: {
	// 	key: 'Germany',
	// 	name: '$vuetify.country.Germany',// 德国
	// 	img: require('../assets/flags/Germany.svg'),
	// 	code: '+49'
	// },
	// Greece: {
	// 	key: 'Greece',
	// 	name: '$vuetify.country.Greece',// 希腊
	// 	img: require('../assets/flags/Greece.svg'),
	// 	code: '+30'
	// },
	// India: {
	// 	key: 'India',
	// 	name: '$vuetify.country.India',// 印度
	// 	img: require('../assets/flags/India.svg'),
	// 	code: '+91'
	// },
	// Italy: {
	// 	key: 'Italy',
	// 	name: '$vuetify.country.Italy',// 意大利
	// 	img: require('../assets/flags/Italy.svg'),
	// 	code: '+39'
	// },
	// Netherlands: {
	// 	key: 'Netherlands',
	// 	name: '$vuetify.country.Netherlands',// 荷兰
	// 	img: require('../assets/flags/Netherlands.svg'),
	// 	code: '+31'
	// },
	// Poland: {
	// 	key: 'Poland',
	// 	name: '$vuetify.country.Poland',// 波兰
	// 	img: require('../assets/flags/Poland.svg'),
	// 	code: '+48'
	// },
	// Portugal: {
	// 	key: 'Portugal',
	// 	name: '$vuetify.country.Portugal',// 葡萄牙
	// 	img: require('../assets/flags/Portugal.svg'),
	// 	code: '+351'
	// },
	// Romania: {
	// 	key: 'Romania',
	// 	name: '$vuetify.country.Romania',// 罗马尼亚
	// 	img: require('../assets/flags/Romania.svg'),
	// 	code: '+40'
	// },
	// Russia: {
	// 	key: 'Russia',
	// 	name: '$vuetify.country.Russia',// 俄罗斯
	// 	img: require('../assets/flags/Russia.svg'),
	// 	code: '+7'
	// },
	// Spain: {
	// 	key: 'Spain',
	// 	name: '$vuetify.country.Spain',// 西班牙
	// 	img: require('../assets/flags/Spain.svg'),
	// 	code: '+34'
	// },
	// Sweden: {
	// 	key: 'Sweden',
	// 	name: '$vuetify.country.Sweden',// 瑞士
	// 	img: require('../assets/flags/Sweden.svg'),
	// 	code: '+46'
	// },
	// Taiwan: {
	// 	key: 'Taiwan',
	// 	name: '$vuetify.country.Taiwan',// 中国台湾
	// 	img: require('../assets/flags/Taiwan.svg'),
	// 	code: '+886'
	// },
	// Thailand: {
	// 	key: 'Thailand',
	// 	name: '$vuetify.country.Thailand',// 泰国
	// 	img: require('../assets/flags/Thailand.svg'),
	// 	code: '+66'
	// },
	// Ukraine: {
	// 	key: 'Ukraine',
	// 	name: '$vuetify.country.Ukraine',// 乌克兰
	// 	img: require('../assets/flags/Ukraine.svg'),
	// 	code: '+380'
	// },
	// USA: {
	// 	key: 'USA',
	// 	name: '$vuetify.country.USA',// 美国
	// 	img: require('../assets/flags/USA.svg'),
	// 	code: '+1'
	// },
	// Vietnam: {
	// 	key: 'Vietnam',
	// 	name: '$vuetify.country.Vietnam',// 越南
	// 	img: require('../assets/flags/Vietnam.svg'),
	// 	code: '+84'
	// },
}