export default {
  badge: 'Abzeichen',
  close: 'Schließen',
  dataIterator: {
    noResultsText: 'Keine Elemente gefunden',
    loadingText: 'Lade Elemente...'
  },
  dataTable: {
    itemsPerPageText: 'Zeilen pro Seite:',
    ariaLabel: {
      sortDescending: 'Absteigend sortiert.',
      sortAscending: 'Aufsteigend sortiert.',
      sortNone: 'Nicht sortiert.',
      activateNone: 'Aktivieren um Sortierung zu entfernen.',
      activateDescending: 'Aktivieren um absteigend zu sortieren.',
      activateAscending: 'Aktivieren um aufsteigend zu sortieren.'
    },
    sortBy: 'Sortiere nach'
  },
  dataFooter: {
    itemsPerPageText: 'Elemente pro Seite:',
    itemsPerPageAll: 'Alle',
    nextPage: 'Nächste Seite',
    prevPage: 'Vorherige Seite',
    firstPage: 'Erste Seite',
    lastPage: 'Letzte Seite',
    pageText: '{0}-{1} von {2}'
  },
  datePicker: {
    itemsSelected: '{0} ausgewählt',
    nextMonthAriaLabel: 'Nächsten Monat',
    nextYearAriaLabel: 'Nächstes Jahr',
    prevMonthAriaLabel: 'Vorheriger Monat',
    prevYearAriaLabel: 'Vorheriges Jahr'
  },
  noDataText: 'Keine Daten vorhanden',
  carousel: {
    prev: 'Vorheriges Bild',
    next: 'Nächstes Bild',
    ariaLabel: {
      delimiter: 'Element {0} von {1}'
    }
  },
  calendar: {
    moreEvents: '{0} mehr'
  },
  fileInput: {
    counter: '{0} Dateien',
    counterSize: '{0} Dateien ({1} gesamt)'
  },
  timePicker: {
    am: 'AM',
    pm: 'PM'
  },
  pagination: {
    ariaLabel: {
      wrapper: 'Seitennavigation',
      next: 'Nächste Seite',
      previous: 'Vorherige Seite',
      page: 'Gehe zu Seite {0}',
      currentPage: 'Aktuelle Seite, Seite {0}'
    }
  },
  rating: {
    ariaLabel: {
      icon: 'Rating {0} of {1}'
    }
  },
	
	
	label: 'Deutsch',
	navBtnText: 'Anmeldung',
	copyright: 'Online SMS LLC',
	orderTitle: 'Aufträge',
	orderDialog: {
		title: 'Kontovermögen',
		orderTitle: 'Meine Bestellungen',
		balanceTitle: 'Mein Gleichgewicht',
		emptyText: 'Bestelllisten sind jetzt leer',
		tips: 'Tipps: Achtung bitte! Löschen Sie nicht den Cache Ihres Geräts. Oder Ihre Bestelllisten und Ihr Guthaben sind weg.',
		table: {
			refresh: 'Aktualisierung',
			finished: 'Fertig',
			refunded: 'Erstattet',
			refundBtnText: 'Jetzt erstatten'
		},
		refundDialog: {
			title: 'Möchten Sie diese Bestellung jetzt zurückerstatten?',
			content: 'Bitte warten Sie eine Minute. Vielleicht ist der Bestätigungscode jetzt unterwegs. Wenn Sie diese Bestellung dennoch zurückerstatten möchten, klicken Sie auf Ja, bitte.',
			disagreeBtnText: 'Nein Danke',
			agreeBtnText: 'Ja',
		},
		thName0: 'Land',
		thName1: 'Meine Nummer',
		thName2: 'Websites',
		thName3: 'Preis',
		thName4: 'Verifizierungs-Schlüssel',
		thName5: 'Status',
		thName6: 'Zahlungszeit',
	},
	settingDialog: {
		title: 'Einstellungen',
		darkMode: 'Dunkler Modus',
		lightMode: 'Lichtmodus',
	},
	walletDialog: {
		title: 'Brieftasche verbinden',
		
	},
	tipsDialog: {
		title: 'Betriebsablauf',
		btnText: 'Link kopieren',
		successTitle: 'Erfolg',
		copyLinkSuccess: 'Kopieren Sie den Link erfolgreich',
		commonInfo: 'Kopieren Sie den Link unten und befolgen Sie diese Schritte.',
		content: {
			imToken: `
				Schritt 1: Klicken Sie auf 'Browser' <br>
				Schritt 2: Klicken Sie auf "Suchleiste". <br>
				Schritt 3: Drücken Sie die Suchleiste, um den zuvor kopierten Link einzufügen.
			`,
			TokenPocket: `
				Schritt 1: Klicken Sie auf „Entdecken“<br>
				Schritt 2: Klicken Sie auf „Suchleiste“<br>
				Schritt 3: Drücken Sie lange auf die Suchleiste, um den zuvor kopierten Link einzufügen.
			`,
			BITPIE: `
				Schritt 1: Klicken Sie auf 'Discover' <br>
				Schritt 2: Klicken Sie auf 'Suchleiste' <br>
				Schritt 3: Lange drücken Sie die Suchleiste, um den Link zu fügen, den Sie zuvor kopiert haben. <br>
				Schritt 4: Wählen Sie die Hauptkette.
			`,
			TronLink: `
				Schritt 1: Klicken Sie auf „Explorer“<br>
				Schritt 2: Klicken Sie auf „Suchleiste“<br>
				Schritt 3: Drücken Sie lange auf die Suchleiste, um den zuvor kopierten Link einzufügen.
			`,
			BitKeep: `
				Schritt 1: Klicken Sie auf 'Dapp' <br>
				Schritt 2: Klicken Sie auf "Suchleiste". <br>
				Schritt 3: Lange drücken Sie die Suchleiste, um den Link zu fügen, den Sie zuvor kopiert haben. <br>
				Schritt 4: Wählen Sie die Hauptkette.
			`,
			Metamask: `
				Schritt 1: Klicken Sie oben links auf das Menü.<br>
				Schritt 2: Wählen Sie „Browser“.<br>
				Schritt 3: Klicken Sie auf die Suchleiste, um den zuvor kopierten Link einzufügen.
			`,
			OKEX: `
				Schritt 1: Wählen Sie „Wallet“ aus<br>
				Schritt 2: Klicken Sie auf „Entdecken“<br>
				Schritt 3: Klicken Sie auf Suchen, um den zuvor kopierten Link einzufügen.
			`,
		}
	},
	country: {
		afghanistan: 'Afghanistan',
		argentina: 'Argentinien',
		austria: 'Österreich',
		azerbaijan: 'Aserbaidschan',
		united_kingdom: 'Großbritannien',
		belgium: 'Belgien',
		belorussia: 'Weißrussland',
		brasil: 'Brasilien',
		bolivia: 'Bolivien',
		bulgaria: 'Bulgarien',
		burkinafaso: 'Burkina Faso',
		cameroon: 'Kamerun',
		canada: 'Kanada',
		chad: 'Tschad',
		china: 'China',
		colombia: 'Kolumbien',
		croatia: 'Kroatien',
		cyprus: 'Zypern',
		czech: 'Tschechisch',
		denmark: 'Dänemark',
		ecuador: 'Ecuador',
		egypt: 'Ägypten',
		estonia: 'Estland',
		finland: 'Finnland',
		france: 'Frankreich',
		gambia: 'Gambia',
		germany: 'Deutschland',
		georgia: 'Georgia',
		ghana: 'Ghana',
		greece: 'Griechenland',
		guinea: 'Guinea',
		guineabissau: 'Guinea-Bissau',
		haiti: 'Haiti',
		honduras: 'Honduras',
		hongkong: 'Hongkong',
		india: 'Indien',
		indonesia: 'Indonesien',
		iraq: 'Irak',
		iran: 'Iran',
		ireland: 'Irland',
		israel: 'Israel',
		italy: 'Italien',
		ivory: 'Elfenbein',
		jordan: 'Jordanien',
		kazakhstan: 'Kasachstan',
		kenya: 'Kenia',
		latvia: 'Lettland',
		liberia: 'Liberia',
		lithuania: 'Litauen',
		malawi: 'Malawi',
		malaysia: 'Malaysia',
		mali: 'Mali',
		mauritania: 'Mauretanien',
		mexico: 'Mexiko',
		moldova: 'Moldawien',
		mongolia: 'Mongolei',
		morocco: 'Marokko',
		nepal: 'Nepal',
		netherlands: 'Niederlande',
		nicaragua: 'Nicaragua',
		nigeria: 'Nigeria',
		northmacedonia: 'Nordmazedonien',
		pakistan: 'Pakistan',
		peru: 'Peru',
		philippines: 'Philippinen',
		poland: 'Polen',
		portugal: 'Portugal',
		romania: 'Rumänien',
		russia: 'Russland',
		salvador: 'Salvador',
		saudiarabia: 'Saudi-Arabien',
		senegal: 'Senegal',
		serbia: 'Serbia',
		sierraleone: 'Sierraleone',
		singapore: 'Singapur',
		spain: 'Spanien',
		srilanka: 'Sri Lanka',
		sudan: 'Sudan',
		swaziland: 'Swasiland',
		sweden: 'Schweden',
		tajikistan: 'Tadschikistan',
		taiwan: 'Taiwan',
		thailand: 'Thailand',
		togo: 'Togo',
		tunis: 'Tunesien',
		turkey: 'Türkei',
		uganda: 'Uganda',
		ukraine: 'Ukraine',
		usa: 'USA',
		uzbekistan: 'Usbekistan',
		venezuela: 'Venezuela',
		vietnam: 'Vietnam',
		yemen: 'Jemen',
		zambia: 'Sambia',
	},
	homePage: {
		bannerTitle: 'SMS online an virtuelle Telefonnummer empfangen',
		bannerContent: 'Zur privaten Registrierung auf verschiedenen Seiten, Diensten und Apps',
		bannerItem0: 'mehr als 70 Länder und mehr als 10.000 Zahlen',
		bannerItem1: 'Kurz- und langfristige Nummernmiete',
		bannerItem2: 'Täglich Telefonnummern hinzufügen',
		bannerBtnText: 'Nummer kaufen',
		receiveSMS: 'SMS empfangen',
		dialogTitle: 'Website finden',
		dialogBtnText: 'Jetzt kaufen',
		dialogCellTitle: 'Preis',
		dialogCellTitle2: 'Zahlungsbedingungen',
		dialogCellTitle3: 'Land',
		dialogPaymentWallet: 'Wallet',
		dialogPaymentBalance: 'Guthaben',
		firstTitle: 'Probieren Sie unsere kostenlosen virtuellen Nummern aus',
		questionTitle: 'Häufig gestellte Fragen',
		showOrderDialog: {
			title: 'Meine Nummern',
			timeTips: 'Telefonnummer ist einsatzbereit.',
			copyTips: 'Kopieren Sie die Nummer und verwenden Sie sie auf der ausgewählten Seite, um SMS zu empfangen.',
			codeText: 'Codenummer'
		}
	},
	help: {
		item0: {
			title: "Wie funktioniert unser Dienst zum Empfangen von SMS-Nachrichten?",
			content: "Wir verwenden unsere eigene Ausrüstung mit SIM-Karten und Software, um virtuelle Nummern für den Empfang einer Nachricht durch Benutzer bereitzustellen. Wenn unsere Kunden eine Nachricht an die gekaufte virtuelle Nummer senden, erhalten wir sie auf der SIM-Karte und die Software überträgt sie in die Schnittstelle eines Online-Dienstes."
		},
		item1: {
			title: "Was ist eine virtuelle Nummer?",
			content: "Dies ist eine Online-Nummer, die ohne SIM-Karte verwendet werden kann, und in unserem Fall ist es eine Nummer, die nur mit unserem SMS-Aktivierungsdienst und nur zum Empfangen eingehender Nachrichten verwendet wird.",
		},
		item2: {
			title: "In welchen Fällen kann ich virtuelle Telefonnummern verwenden?",
			content: "Normalerweise verwenden Menschen unsere virtuellen Nummern zum Empfangen von SMS, wenn sie sich in sozialen Netzwerken (VK, OK, Facebook, Instagram) registrieren, Messenger (Telegram, WhatsApp, Viber, WeChat) und E-Mails (Yandex, Mail.ru , Gmail, Yahoo), sowie für andere Online-Sites und beliebte Dienste: Avito, Yula, AUTO.RU, Twitter, SEOsprint, Steam, Uber, Gett, Microsoft, Tinder. Außerdem können vorübergehend gefälschte Telefonnummern zum Empfang verwendet werden Online einmalige private Nachrichten, um anonym zu bleiben und keine SIM-Karte für einen einmaligen SMS-Versand zu kaufen.",
		},
		item3: {
			title: "Kann ich eine SIM-Karte bekommen, mit der die virtuelle Nummer verknüpft ist?",
			content: "Unser Service funktioniert nur so, dass die Leute keine SIM-Karte kaufen müssen. Bei uns verwenden die Leute eine gekaufte Online-Nummer ohne SIM-Karte. Solche Nummern werden in Ihrer persönlichen Kontooberfläche angezeigt, wo Sie SMS-Nachrichten sehen können einmalige Codes zur Aktivierung und Bestätigung.",
		},
		item4: {
			title: "Erhält jemand nach Ablauf des Dienstes Zugriff auf die Nummer, die ich verwendet habe?",
			content: "Wenn Sie eine SMS an die gekaufte virtuelle Nummer erhalten, dann wird diese Nummer niemals jemandem für die angegebene Seite oder Anwendung angeboten. Also beispielsweise Vorfälle, bei denen sich jemand Zugang zu Ihrem sozialen Netzwerk oder Messenger verschafft ganz von unserem Service ausgeschlossen. Das heißt, unsere individuellen Rufnummern werden für jeden Nutzer individuell vergeben.",
		},
		item5: {
			title: "Was ist eine einmalige Nummernmiete (SMS von einer Seite empfangen)?",
			content: "Mit diesem Tarif erhalten Sie je nach Land eine virtuelle Nummer für einen Zeitraum von 10 Minuten bis 1 Stunde. Der einmalige Mietservice ermöglicht den Empfang einer unbegrenzten Anzahl von SMS-Nachrichten für die zugewiesene Zeit und nur von einer ausgewählten Site oder Anwendung.",
		},
		item6: {
			title: "Was ist, wenn ich keine SMS empfangen kann?",
			content: "Wenn Sie Ihre Nachricht nicht unter der von Ihnen bestellten temporären Nummer erhalten haben (solche Vorfälle können aus verschiedenen Gründen auftreten, z Gehen Sie zurück zum Guthaben, um eine neue virtuelle Nummer zu bestellen.",
		},
		item7: {
			title: "Kann ich Ihren Dienst für den Empfang von Nachrichten und SMS-Aktivierungen testen?",
			content: "Natürlich können Sie das. Dazu haben wir auf dieser Seite einen speziellen Block mit temporären kostenlosen Nummern für den einmaligen SMS-Empfang hinzugefügt. Solche Nummern sind öffentlich und können daher in einigen beliebten Diensten nicht verwendet werden. Aber Im Gegensatz zu den meisten Aktivierungsdiensten aktualisieren wir unsere kostenlose Nummernliste jeden Tag.",
		},
	},
	tips: {
		errorTitle: 'Fehler',
		successTitle: 'Erfolg',
		buyError: 'Bitte suchen Sie eine Website, für die Sie SMS erhalten möchten',
		copyNumberSuccess: 'Nummer kopieren erfolgreich',
		connectWalletFailText: 'Wallet konnte nicht verbunden werden!',
		loginSuccessText: 'Erfolgreiche Anmeldung mit Ihrem Wallet',
		loginFailText: 'Anmeldung mit Ihrer Brieftasche fehlgeschlagen',
		refundFailText: 'Vorübergehend keine Rückerstattung',
		refundErrorText: 'Etwas ist schief gelaufen. Bitte aktualisieren Sie die Website.',
		refundSuccessText: 'Rückerstattung erfolgreich',
		balanceFailText: 'Entschuldigung, Ihr Guthaben geht zur Neige',
		transferFailText: 'Entschuldigung, Übertragung fehlgeschlagen',
		unLoginText: 'Ihr Konto ist nicht angemeldet. Bitte klicken Sie auf die Schaltfläche in der oberen rechten Ecke, um Ihr Konto anzumelden.',
		chainNotSupportCoin: 'Entschuldigung, diese Kette unterstützt diese Art von Münzen derzeit nicht.'
	},
	footerLink: {
		link0: 'Allgemeine Geschäftsbedingungen',
		link1: 'Datenschutzerklärung',
	},
	month: {
		Oct: 'Okt',
	}
};