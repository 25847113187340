const CACHE_ORDER_KEY = "cache_order_key";
import store from '@/js/store';
import {PRICE_RATIO}  from '@/env';
/**
 *  {orderstatus:订单状态  1=已支付  2=已退款 , paymenttime:支付时间}
 */
export default {
	
	setOrderList(tzid, text){
		if(tzid){
			let orsers = this.getOrderList();
			if(orsers[tzid]){
				orsers[tzid] = Object.assign(orsers[tzid], text);
			} else {
				orsers[tzid] = text;
			}
			localStorage.setItem(CACHE_ORDER_KEY, JSON.stringify(orsers));
		}
	},
	
	getOrderList(){
		let orsers = localStorage.getItem(CACHE_ORDER_KEY);
		if(orsers){
			// console.log(JSON.parse(orsers));
			return JSON.parse(orsers);
		}
		return {};
	},
	
	removeOrder(key){
		let orsers = this.getOrderList();
		delete orsers[key]; 
		localStorage.setItem(CACHE_ORDER_KEY, JSON.stringify(orsers));
	},
	
	refundOrder(tzid){
		let orsers = this.getOrderList();
		let order = orsers[tzid];
		if(!order.msg && order.orderstatus == 1){
			order.orderstatus = 2;
			// this.setOrderList(tzid, order);
			store.commit("orderList", {id:tzid, text:order});
			let chageBalance = order.sum * PRICE_RATIO;
			chageBalance = chageBalance.toFixed(2);
			store.dispatch('chageBalance', chageBalance);
		}else{
			throw '$vuetify.tips.refundErrorText';
		}
	}
	
	
}