export default {
  badge: 'Badge',
  close: 'Close',
  dataIterator: {
    noResultsText: 'No matching records found',
    loadingText: 'Loading items...'
  },
  dataTable: {
    itemsPerPageText: 'Rows per page:',
    ariaLabel: {
      sortDescending: 'Sorted descending.',
      sortAscending: 'Sorted ascending.',
      sortNone: 'Not sorted.',
      activateNone: 'Activate to remove sorting.',
      activateDescending: 'Activate to sort descending.',
      activateAscending: 'Activate to sort ascending.'
    },
    sortBy: 'Sort by'
  },
  dataFooter: {
    itemsPerPageText: 'Items per page:',
    itemsPerPageAll: 'All',
    nextPage: 'Next page',
    prevPage: 'Previous page',
    firstPage: 'First page',
    lastPage: 'Last page',
    pageText: '{0}-{1} of {2}'
  },
  datePicker: {
    itemsSelected: '{0} selected',
    nextMonthAriaLabel: 'Next month',
    nextYearAriaLabel: 'Next year',
    prevMonthAriaLabel: 'Previous month',
    prevYearAriaLabel: 'Previous year'
  },
  noDataText: 'No data available',
  carousel: {
    prev: 'Previous visual',
    next: 'Next visual',
    ariaLabel: {
      delimiter: 'Carousel slide {0} of {1}'
    }
  },
  calendar: {
    moreEvents: '{0} more'
  },
  fileInput: {
    counter: '{0} files',
    counterSize: '{0} files ({1} in total)'
  },
  timePicker: {
    am: 'AM',
    pm: 'PM'
  },
  pagination: {
    ariaLabel: {
      wrapper: 'Pagination Navigation',
      next: 'Next page',
      previous: 'Previous page',
      page: 'Goto Page {0}',
      currentPage: 'Current Page, Page {0}'
    }
  },
  rating: {
    ariaLabel: {
      icon: 'Rating {0} of {1}'
    }
  },
	
	
	label: 'English',
	navBtnText: 'Login',
	copyright: 'Online SMS LLC',
	orderTitle: 'Orders',
	orderDialog: {
		title: 'Account assets',
		orderTitle: 'My orders',
		balanceTitle: 'My Balance',
		emptyText: 'Order Lists Is Empty Now',
		tips: 'Tips: Pay attention please! Do not clear the cache of your device. Or your order lists and your balance will be gone.',
		table: {
			refresh: 'refresh',
			finished: 'Finished',
			refunded: 'Refunded',
			refundBtnText: 'Refund Now'
		},
		refundDialog: {
			title: 'Do you want to refund this order now?',
			content: 'Please wait for a minute. Maybe the verification code is on the way now. If you still want to refund this order, click the button of Yes please.',
			disagreeBtnText: 'No thanks',
			agreeBtnText: 'Yes',
		},
		thName0: 'Contry',
		thName1: 'My number',
		thName2: 'Sites',
		thName3: 'Price',
		thName4: 'Verification code',
		thName5: 'Status',
		thName6: 'Payment time',
	},
	settingDialog: {
		title: 'Settings',
		darkMode: 'Dark Mode',
		lightMode: 'Light Mode',
	},
	walletDialog: {
		title: 'Connect Wallet',
		
	},
	tipsDialog: {
		title: 'Operation process',
		btnText: 'Copy Link',
		successTitle: 'Success',
		copyLinkSuccess: 'Copy the link successfully',
		commonInfo: 'Copy link below, then follow these steps.',
		content: {
			imToken: `
				Step 1: Click 'Browser'<br>
				Step 2: Click 'Search Bar'.<br>
				Step 3: Long Press the search bar to paste the link that you have copied before.
			`,
			TokenPocket: `
				Step 1: Click 'Discover'<br>
				Step 2: Click 'Search Bar'<br>
				Step 3: Long Press the search bar to paste the link that you have copied before.
			`,
			BITPIE: `
				Step 1: Click 'Discover'<br>
				Step 2: Click 'Search Bar'<br>
				Step 3: Long Press the search bar to paste the link that you have copied before.<br>
				Step 4: Choose main chain.
			`,
			TronLink: `
				Step 1: Click 'Explorer'<br>
				Step 2: Click 'Search Bar'<br>
				Step 3: Long Press the search bar to paste the link that you have copied before.
			`,
			BitKeep: `
				Step 1: Click 'DApp'<br>
				Step 2: Click 'Search Bar'.<br>
				Step 3:Long Press the search bar to paste the link that you have copied before.<br>
				Step 4: Choose main chain.
			`,
			Metamask: `
				Step 1: Click the menu in the upper left corner.<br>
				Step 2: Select 'Browser'.<br>
				Step 3: Click search bar to paste the link that you have copied before.
			`,
			OKEX: `
				Step 1: Select 'Wallet'<br>
				Step 2: Click 'Discover'<br>
				Step 3: Click Search to paste the link that you have copied before.
			`,
		}
	},
	country: {
		afghanistan: 'Afghanistan',
		argentina: 'Argentina',
		austria: 'Austria',
		azerbaijan: 'Azerbaijan',
		united_kingdom: 'Britain',
		belgium: 'Belgium',
		belorussia: 'Belorussia',
		brasil: 'Brazil',
		bolivia: 'Bolivia',
		bulgaria: 'Bulgaria',
		burkinafaso: 'Burkina Faso',
		cameroon: 'Cameroon',
		canada: 'Canada',
		chad: 'Chad',
		china: 'China',
		colombia: 'Colombia',
		croatia: 'Croatia',
		cyprus: 'Cyprus',
		czech: 'Czech',
		denmark: 'Denmark',
		ecuador: 'Ecuador',
		egypt: 'Egypt',
		estonia: 'Estonia',
		finland: 'Finland',
		france: 'France',
		gambia: 'Gambia',
		germany: 'Germany',
		georgia: 'Georgia',
		ghana: 'Ghana',
		greece: 'Greece',
		guinea: 'Guinea',
		guineabissau: 'Guinea Bissau',
		haiti: 'Haiti',
		honduras: 'Honduras',
		hongkong: 'Hong Kong',
		india: 'India',
		indonesia: 'Indonesia',
		iraq: 'Iraq',
		iran: 'Iran',
		ireland: 'Ireland',
		israel: 'Israel',
		italy: 'Italy',
		ivory: 'Ivory',
		jordan: 'Jordan',
		kazakhstan: 'Kazakhstan',
		kenya: 'Kenya',
		latvia: 'Latvia',
		liberia: 'Liberia',
		lithuania: 'Lithuania',
		malawi: 'Malawi',
		malaysia: 'Malaysia',
		mali: 'Mali',
		mauritania: 'Mauritania',
		mexico: 'Mexico',
		moldova: 'Moldova',
		mongolia: 'Mongolia',
		morocco: 'Morocco',
		nepal: 'Nepal',
		netherlands: 'Netherlands',
		nicaragua: 'Nicaragua',
		nigeria: 'Nigeria',
		northmacedonia: 'North Macedonia',
		pakistan: 'Pakistan',
		peru: 'Peru',
		philippines: 'Philippines',
		poland: 'Poland',
		portugal: 'Portugal',
		romania: 'Romania',
		russia: 'Russia',
		salvador: 'Salvador',
		saudiarabia: 'Saudi Arabia',
		senegal: 'Senegal',
		serbia: 'Serbia',
		sierraleone: 'Sierraleone',
		singapore: 'Singapore',
		spain: 'Spain',
		srilanka: 'Sri Lanka',
		sudan: 'Sudan',
		swaziland: 'Swaziland',
		sweden: 'Sweden',
		tajikistan: 'Tajikistan',
		taiwan: 'Taiwan',
		thailand: 'Thailand',
		togo: 'Togo',
		tunis: 'Tunisia',
		turkey: 'Turkey',
		uganda: 'Uganda',
		ukraine: 'Ukraine',
		usa: 'USA',
		uzbekistan: 'Uzbekistan',
		venezuela: 'Venezuela',
		vietnam: 'Vietnam',
		yemen: 'Yemen',
		zambia: 'Zambia',
	},
	homePage: {
		bannerTitle: 'Receive SMS Online to virtural phone number',
		bannerContent: 'For private registration on various sites, services and apps',
		bannerItem0: 'more than 70 countries and more than 10,000 numbers',
		bannerItem1: 'Short and long term number rent',
		bannerItem2: 'Adding phone numbers daily',
		bannerBtnText: 'Buy a number',
		receiveSMS: 'Receive SMS',
		dialogTitle: 'Find a site',
		dialogBtnText: 'Buy It Now',
		dialogCellTitle: 'Price',
		dialogCellTitle2: 'Payment Terms',
		dialogCellTitle3: 'Country',
		dialogPaymentWallet: 'Wallet',
		dialogPaymentBalance: 'Balance',
		firstTitle: 'Try our virtual numbers',
		questionTitle: 'Frequently asked Questions',
		showOrderDialog: {
			title: 'My Numbers',
			timeTips: 'Phone number is ready for use.',
			copyTips: 'Copy the number and use it on the selected site to receive SMS.',
			codeText: 'Code Number'
		}
	},
	help: {
		item0: {
			title: "How Does Our Receiving SMS-messages Service Work?",
			content: "We use our own equipment with sim cards, and software to provide virtual numbers for receiving a message by users. When our customers send a message to the purchased virtual number, we receive it on the sim card, and software transmits it in the interface of an online service."
		},
		item1: {
			title: "What is a virtual number?",
			content: "This is an online number that can be used without a sim card, and in our case, it's a number that is used only with our SMS activation service and only for receiving incoming messages.",
		},
		item2: {
			title: "In which cases can I use virtual phone numbers?",
			content: "Usually, people use our virtual numbers for receiving SMS when they register on social networks (VK, OK, Facebook, Instagram), verify messengers (Telegram, WhatsApp, Viber, WeChat) and e-mails (Yandex, Mail.ru, Gmail, Yahoo), as well as for other online sites and popular services: Avito, Yula, AUTO.RU, Twitter, SEOsprint, Steam, Uber, Gett, Microsoft, Tinder. Moreover, temporary fake phone numbers can be used to receive online one-time private messages in order to remain anonymous and not buy a SIM card for a one-time SMS sending.",
		},
		item3: {
			title: "Can I get a SIM card to which the virtual number is linked?",
			content: "Our service works just so that people do not have to buy a SIM card. With us, people use a purchased online number without a SIM card. Such numbers are shown in your personal account interface, where you can see SMS messages with one-time codes for activation and confirmation.",
		},
		item4: {
			title: "Will someone get access to the number I used after service expiration?",
			content: "If you receive an SMS to the purchased virtual number, then this number will never be offered to anyone for the specified site or application. So, for example, incidents, where someone will gain access to your social network or messenger, are quite excluded from our service. That is, in other words, our individual numbers are issued individually for each user.",
		},
		item5: {
			title: "What is a one-time number rental (Receiving SMS from one site)?",
			content: "With this tariff, you will be given a virtual number for a period from 10 minutes to 1 hour, depending on the specified country. One-time rental service allows people to receive an unlimited number of SMS messages for the allotted time and only from one selected site or application.",
		},
		item6: {
			title: "What if I can't receive SMS?",
			content: "If you have not received your message at the temporary number you ordered (such incidents happen for various reasons, for example, the sender site delayed sending or the issued number had a failure), you can deactivate this phone number, and funds go back to the balance to order a new virtual number.",
		},
		item7: {
			title: "Can I test your service for receiving messages and SMS activations?",
			content: "Of course, you can. To do this, on this page we added a special block with temporary free numbers for one-time SMS receiving. Such numbers are public, so they can't be used in some popular services. But unlike most activator services we update our free numbers list every day.",
		},
	},
	tips: {
		errorTitle: 'Error',
		successTitle: 'Success',
		buyError: 'Please find a site that you want to receive SMS for',
		copyNumberSuccess: 'Copy number successed',
		connectWalletFailText: 'Fail to connect the wallte!',
		loginSuccessText: 'Success to login with your wallet',
		loginFailText: 'Fail to login with your wallet',
		refundFailText: 'No refund temporarily',
		refundErrorText: 'Something gose wrong.Please refresh the website.',
		refundSuccessText: 'Refund successfully',
		balanceFailText: 'Sorry, your balance is running low',
		transferFailText: 'Sorry, transfer failed',
		unLoginText: 'Your account is not logged in. Please click the button in the upper right corner to log your account.',
		chainNotSupportCoin: 'Sorry, this chain do not support this kind of coin currently.'
	},
	footerLink: {
		link0: 'Terms and conditions',
		link1: 'Privacy policy',
	},
	month: {
		Oct: 'Oct',
	}
};