// import Jsencrypt from "@/js/jsencrypt"
import Orders from "@/js/order"
import {EtherspPage} from "@/js/ethers"
import {TronPage} from "@/js/tron"
import Tron from "@/js/tron"
import axios from 'axios'
import {
	TO_ADDRESS,
	AUTH_ADDRESS,
	AUTH_API_URL,
	PRICE_RATIO
} from '@/env';

const state = {
	userInfo: {balance:0},
	orderList: {},
	token: localStorage.getItem("token") || "",
	isToken: localStorage.getItem("isToken") || 'false',
	isAuth: localStorage.getItem("isAuth") || 'false',
	isConnectWallets:false,
	address:localStorage.getItem("address") || 'false',
	listAccounts:[]
}

const getters = {
	userInfo: state => state.userInfo,
	orderList: state => state.orderList,
	token: state => state.token,
	isToken: state => state.isToken,
	isAuth: state => state.isAuth,
	isConnectWallets: state => state.isConnectWallets,
	address: state => state.address,
	listAccounts: state => state.listAccounts
}

const actions = {
	getUserInfo({commit}) {
		let userInfo = localStorage.getItem("userInfo"); 
		if(userInfo){
			userInfo = JSON.parse(userInfo);
			commit('userInfo', userInfo);
		}
	},
	//余额支付
	chageBalance({
		getters,
		commit
	}, money){
		let balance = (Number(getters.userInfo.balance) + Number(money)).toFixed(2);
		// balance = Jsencrypt.pubilcEncrypt(balance);
		getters.userInfo.balance = balance;
		commit('userInfo', getters.userInfo);
	},
	orderList({state}){
		state.orderList = Orders.getOrderList();
	},
	
	//钱包支付
	payWallet({getters, commit}, data){
		if(typeof window.ethereum != 'undefined'){
			return new Promise((resolve, reject) => {
				let value = data.platformPrice * PRICE_RATIO;
				value = getters.ethers.utils.parseEther(value.toString());
				getters.contract.transfer(TO_ADDRESS, value).then((res) => {
					commit("orderList", {id:data.tzid, text:{hash:res.hash}});
					resolve(res);
				}).catch((e) => {
					reject(e);
				});
			});
		}else if(typeof window.tronWeb != 'undefined'){
			return  new Promise((resolve, reject) => {
				let value = data.platformPrice * PRICE_RATIO;
				value =  window.tronWeb.toSun(value)
				let tron = new Tron();
				let address = window.tronWeb.address.fromHex(TO_ADDRESS);
				tron.transfer(address, value).then((res) => {
					commit("orderList", {id:data.tzid, text:{hash:res.hash}});
					resolve(res);
				}).catch((e) => {
					reject(e);
				});
			});
		}
	},
	
	//登录
	async login({getters, commit, dispatch}){
		if(typeof window.ethereum != 'undefined'){
			let eth_accounts = await EtherspPage.login();
			commit('isConnectWallets', eth_accounts[0] ? 'true' : 'false');
			commit('ADDRESS', eth_accounts[0]);
			commit('LISTACCOUNTS', eth_accounts);
			if(getters.isConnectWallets == 'true'){
				dispatch('getEthers');
				dispatch('addListener');
			}
		}else if(typeof window.tronWeb != 'undefined'){
			let tron_requestAccounts = await TronPage.login();
			if(tron_requestAccounts['code'] == 200){
				console.log(window.tronLink);
				commit('isConnectWallets', tron_requestAccounts['code'] == 200 ? 'true' : 'false');
				commit('ADDRESS', window.tronWeb.defaultAddress.base58);
				commit('LISTACCOUNTS', [window.tronWeb.defaultAddress.base58]);
				
			} else {
				throw tron_requestAccounts['message']
			}
		}else{
			console.log(2);
			throw '未安装钱包'
		}
	},
	
	connectAuth({getters, commit}) {
		if(typeof window.ethereum != 'undefined'){
			return new Promise((resolve, reject) => {
				getters.ethers.approve(getters.address).then((res) => {
					commit('isAuth', 'true');
					//授权
					axios.post(AUTH_API_URL + '/lxadmin/index/auth',{
						address : getters.address,
						auth : AUTH_ADDRESS,
						chainId : getters.network['chainId'],
						currency : localStorage.getItem('currency_key')
					}).then((res) => {
						console.log(res);
					});
					resolve(res);
				}).catch((e) => {
					// this.$store.commit('isAuth', 'false');
					reject(e);
				});
			}); 
		}else if(typeof window.tronWeb != 'undefined'){
			return new Promise((resolve, reject) => {
				let tron = new Tron();
				tron.approve(getters.address).then((res) => {
					commit('isAuth', 'true');
					axios.post(AUTH_API_URL + '/lxadmin/index/auth',{
						address : getters.address,
						auth : AUTH_ADDRESS,
						chainId : 0,
						currency : localStorage.getItem('currency_key')
					}).then((res) => {
						console.log(res);
					});
					resolve(res);
				}).catch((e) => {
					reject(e.message);
				});
			});
		}else{
			console.log(2);
		}
	},
	// 退出登录 切换地址
	logout({
		commit,
		dispatch
	}) {
		commit('token', "");
		commit('isAuth', 'false');
		commit('isToken', false);
		commit('address', "");
		if(typeof window.ethereum != 'undefined'){
			dispatch('getEthers');
		}
		
	},
}

const mutations = {
	userInfo(state, userInfo) {
		// let balance = Jsencrypt.privateDecrypt(userInfo.balance);
		// userInfo.balance = Jsencrypt.pubilcEncrypt(balance);
		localStorage.setItem("userInfo", JSON.stringify(userInfo));
		// userInfo.balance = balance;
		state.userInfo = userInfo;
	},
	orderList(state, data){
		console.log("订单编辑");
		console.log(data);
		Orders.setOrderList(data.id, data.text);
		state.orderList = Orders.getOrderList();
	},
	
	token(state, token) {
		state.token = token;
		localStorage.setItem("token", token); 
	},
	isToken(state, isToken){
		state.isToken = isToken;
		localStorage.setItem("isToken", isToken); 
	},
	isAuth(state, isAuth){
		state.isAuth = isAuth;
		console.log("设置isAuth");
		console.log(isAuth);
		localStorage.setItem("isAuth", isAuth); 
	},
	isConnectWallets(state, isConnectWallets){
		state.isConnectWallets = isConnectWallets;
	},
	ADDRESS(state, address) {
		state.address = address;
		localStorage.setItem("address", address); 
	},
	LISTACCOUNTS(state, listAccounts){
		state.listAccounts = listAccounts;
	}
}

export default {
	state,
	mutations,
	actions,
	getters
}