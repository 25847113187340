import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import user from './modules/user.js'
import ethers from './modules/ethers.js'

const store = new Vuex.Store({
	modules: {
		ethers,
		user
	}
})

export default store