import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
// 引入组件和页面
// import AboutMe from '../pages/AboutMe';
// import HomePage from '../pages/HomePage'
const routes=[
	{
		// 每次进入默认进入该路由
		path: '/',
		// redirect属性，如果是默认路由，则跳转
		redirect: '/index',
		// 可以为路由进行命名,可以通过name进行路由跳转
		name: 'App',
		// 可以保存路由里的一些信息，相当于html里的meta元信息,你可以通过$route里来获取
		meta: {},
		// 该路由下展示的子路由，必须在路由组件中写router-view标签才能展示
		// children: [
		// 	{
		// 		path: '#/wallet',
		// 		name: 'WalletDialog',
		// 		component: (resolve) => {
		// 			require(['../components/WalletDialog'], resolve)
		// 		},
		// 	}
		// ]
	},
	{
		path:'/index',
		name: 'HomePage',
		component: (resolve) => {
			require(['../pages/HomePage'], resolve)
		},
	},
	{
		path:'/offer',
		name: 'TermsConditions',
		component: (resolve) => {
			require(['../pages/TermsConditions'], resolve)
		},
	},
	{
		path:'/privacy',
		name: 'PrivacyPolicy',
		component: (resolve) => {
			require(['../pages/PrivacyPolicy'], resolve)
		},
	},
]
export default new VueRouter({
	mode: 'history',
	// 路由表
	routes,
	// 保存进入过路由的滚动位置
	scrollBehavior (to, from, savedPosition) {
		to // 跳转的路由
		from // 从哪跳转的路由
		savedPosition // 如果该路由进入过并滚动，该参数会保存滚动的位置信息
		// 我们通常会这样设置
		if (savedPosition) {
			return savedPosition
		}
	},
	// 并不是所有的浏览器都支持vue形式的路由跳转，该属性不用设置自动生效就是为了让不同的浏览器支持vue形式的路由跳转。
	fallback: true,
	// 该方法会自动获取路径中的query参数，它会接收个参数是字符串
	// parseQuery (query) {
	// 	console.log(query)
	// },
	// // 该方法会自动获取路径中的query参数，它会接收个参数是对象
	// stringifyQuery (obj) {
	// 	console.log(obj)
	// }
});