<template>
	<div>
		<!-- 多语言选择菜单 -->
		<v-menu
			:top="top"
			right
			open-on-hover
			offset-y
			origin="center center"
			transition="scale-transition"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					text
					slot="activator"
					v-bind="attrs"
					v-on="on"
					small
					class="hidden-md-and-up px-2"
				>
					<v-icon size="18">mdi-web</v-icon>
					<!-- 显示当前语言名称 -->
					<v-icon small>mdi-menu-down</v-icon>
				</v-btn>
				<v-btn
					text
					slot="activator"
					v-bind="attrs"
					v-on="on"
					class="hidden-sm-and-down"
				>
					<v-icon size="20">mdi-web</v-icon>
					<!-- 显示当前语言名称 -->
					<span class="ml-1"> {{ localeText }} </span>
					<v-icon small>mdi-menu-down</v-icon>
				</v-btn>
			</template>
			<!-- 显示所有语言包组成的下拉菜单 -->
			<v-list class="overflow-y-auto" max-height="360px" width="220px">
				<v-list-item-group v-model="$vuetify.lang.current">
					<v-list-item
						@click="handleChangeLocale(item)"
						v-for="item in availableLanguages"
						:key="item.value"
						:value="item.value"
					>
						<v-list-item-title v-text="item.text" />
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
export default {
	name: 'LocaleLanguage',
	props: {
		top: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		// 设置当前语言
		handleChangeLocale({ value }) {
			this.$vuetify.lang.current = value;
		},
	},

    computed: {
		// 遍历并获取项目中的所有语言包
		availableLanguages() {
			const { locales } = this.$vuetify.lang; // 所有的语言，来自 src/plugins/vuetify.js 中的配置
			return Object.keys(locales).map((lang) => {
				return {
					text: locales[lang].label, // 语言包显示的名字
					value: lang, // 语言包的值
				};
			});
		},
		// 当前语言, 用于语言切换菜单中显示的当前语言名字
		localeText() {
			const find = this.availableLanguages.find(
				(item) => item.value === this.$vuetify.lang.current
			);
			return find.text;
		},
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
