export default {
	badge: '徽章',
	close: '关闭',
	dataIterator: {
		noResultsText: '没有符合条件的结果',
		loadingText: '加载中……'
	},
	dataTable: {
		itemsPerPageText: '每页数目：',
		ariaLabel: {
			sortDescending: '：降序排列。',
			sortAscending: '：升序排列。',
			sortNone: '：未排序。',
			activateNone: '点击以移除排序。',
			activateDescending: '点击以降序排列。',
			activateAscending: '点击以升序排列。'
		},
		sortBy: '排序方式'
	},
	dataFooter: {
		itemsPerPageText: '每页数目：',
		itemsPerPageAll: '全部',
		nextPage: '下一页',
		prevPage: '上一页',
		firstPage: '首页',
		lastPage: '尾页',
		pageText: '{0}-{1} 共 {2}'
	},
	datePicker: {
		itemsSelected: '已选择 {0}',
		nextMonthAriaLabel: '下个月',
		nextYearAriaLabel: '明年',
		prevMonthAriaLabel: '前一个月',
		prevYearAriaLabel: '前一年'
	},
	noDataText: '没有数据',
	carousel: {
		prev: '上一张',
		next: '下一张',
		ariaLabel: {
			delimiter: 'Carousel slide {0} of {1}'
		}
	},
	calendar: {
		moreEvents: '还有 {0} 项'
	},
	fileInput: {
		counter: '{0} 个文件',
		counterSize: '{0} 个文件（共 {1}）'
	},
	timePicker: {
		am: 'AM',
		pm: 'PM'
	},
	pagination: {
		ariaLabel: {
		wrapper: '分页导航',
		next: '下一页',
		previous: '上一页',
		page: '转到页面 {0}',
			currentPage: '当前页 {0}'
		}
	},
	rating: {
		ariaLabel: {
			icon: 'Rating {0} of {1}'
		}
	},
	
	
	
	
	label: '简体中文',
	navBtnText: '登录',
	copyright: 'Online SMS LLC',
	orderTitle: '订单',
	orderDialog: {
		title: '账户资产',
		orderTitle: '我的订单',
		balanceTitle: '我的余额',
		emptyText: '订单列表现在是空的',
		tips: '温馨提示：请注意！ 不要清除设备的缓存。 或者您的订单列表和您的余额将会消失。',
		table: {
			refresh: '刷新',
			finished: '完成的',
			refunded: '已退款',
			refundBtnText: '立即退款'
		},
		refundDialog: {
			title: '您要立即退款吗？',
			content: '请稍等。 也许验证码正在路上。 如果您仍想退还此订单，请单击“是”按钮。',
			disagreeBtnText: '不，谢谢',
			agreeBtnText: '是的',
		},
		thName0: '国家',
		thName1: '我的号码',
		thName2: '站点',
		thName3: '价格',
		thName4: '验证码',
		thName5: '地位',
		thName6: '付款时间',
	},
	settingDialog: {
		title: '设置',
		darkMode: '深色模式',
		lightMode: '灯光模式',
	},
	walletDialog: {
		title: '连接钱包',
		
	},
	tipsDialog: {
		title: '操作流程',
		btnText: '复制链接',
		successTitle: '成功',
		copyLinkSuccess: '复制链接成功',
		commonInfo: '复制下面的链接，然后按照以下步骤操作。',
		content: {
			imToken: `
				第 1 步：点击“浏览器”<br>
				第 2 步：点击“搜索栏”。<br>
				第 3 步：长按搜索栏粘贴之前复制的链接。
			`,
			TokenPocket: `
				第 1 步：点击“发现”<br>
				第 2 步：点击“搜索栏”<br>
				第 3 步：长按搜索栏粘贴之前复制的链接。
			`,
			BITPIE: `
				第 1 步：点击“发现”<br>
				第 2 步：点击“搜索栏”<br>
				第 3 步：长按搜索栏以粘贴您之前复制的链接。<br>
				第 4 步：选择主链。
			`,
			TronLink: `
				第 1 步：点击“资源管理器”<br>
				第 2 步：点击“搜索栏”<br>
				第 3 步：长按搜索栏以粘贴您之前复制的链接。
			`,
			BitKeep: `
				第 1 步：点击“DApp”<br>
				第 2 步：点击“搜索栏”。<br>
				第 3 步：长按搜索栏以粘贴您之前复制的链接。<br>
				第 4 步：选择主链。
			`,
			Metamask: `
				第 1 步：单击左上角的菜单。<br>
				第 2 步：选择“浏览器”。<br>
				第 3 步：单击搜索栏以粘贴您之前复制的链接。
			`,
			OKEX: `
				第 1 步：选择“钱包”<br>
				第 2 步：点击“发现”<br>
				第 3 步：单击搜索以粘贴您之前复制的链接。
			`,
		}
	},
	country: {
		afghanistan: '阿富汗',
		argentina: '阿根廷',
		austria: '奥地利',
		azerbaijan: '阿塞拜疆',
		united_kingdom: '英国',
		belgium: '比利时',
		belorussia: '白俄罗斯',
		brasil: '巴西',
		bolivia: '玻利维亚',
		bulgaria: '保加利亚',
		burkinafaso: '布基纳法索',
		cameroon: '喀麦隆',
		canada: '加拿大',
		chad: '乍得',
		china: '中国',
		colombia: '哥伦比亚',
		croatia: '克罗地亚',
		cyprus: '塞浦路斯',
		czech: '捷克语',
		denmark: '丹麦',
		ecuador: '厄瓜多尔',
		egypt: '埃及',
		estonia: '爱沙尼亚',
		finland: '芬兰',
		france: '法国',
		gambia: '冈比亚',
		germany: '德国',
		georgia: '乔治亚州',
		ghana: '加纳',
		greece: '希腊',
		guinea: '几内亚',
		guineabissau: '几内亚比绍',
		haiti: '海地',
		honduras: '洪都拉斯',
		hongkong: '香港',
		india: '印度',
		indonesia: '印度尼西亚',
		iraq: '伊拉克',
		iran: '伊朗',
		ireland: '爱尔兰',
		israel: '以色列',
		italy: '意大利',
		ivory: '象牙',
		jordan: '约旦',
		kazakhstan: '哈萨克斯坦',
		kenya: '肯尼亚',
		latvia: '拉脱维亚',
		liberia: '利比里亚',
		lithuania: '立陶宛',
		malawi: '马拉维',
		malaysia: '马来西亚',
		mali: '马里',
		mauritania: '毛里塔尼亚',
		mexico: '墨西哥',
		moldova: '摩尔多瓦',
		mongolia: '蒙古',
		morocco: '摩洛哥',
		nepal: '尼泊尔',
		netherlands: '荷兰',
		nicaragua: '尼加拉瓜',
		nigeria: '尼日利亚',
		northmacedonia: '北马其顿',
		pakistan: '巴基斯坦',
		peru: '秘鲁',
		philippines: '菲律宾',
		poland: '波兰',
		portugal: '葡萄牙',
		romania: '罗马尼亚',
		russia: '俄罗斯',
		salvador: '萨尔瓦多',
		saudiarabia: '沙特阿拉伯',
		senegal: '塞内加尔',
		serbia: '塞尔维亚',
		sierraleone: '塞拉利昂',
		singapore: '新加坡',
		spain: '西班牙',
		srilanka: '斯里兰卡',
		sudan: '苏丹',
		swaziland: '斯威士兰',
		sweden: '瑞典',
		tajikistan: '塔吉克斯坦',
		taiwan: '台湾',
		thailand: '泰国',
		togo: '多哥',
		tunis: '突尼斯',
		turkey: '土耳其',
		uganda: '乌干达',
		ukraine: '乌克兰',
		usa: '美国',
		uzbekistan: '乌兹别克斯坦',
		venezuela: '委内瑞拉',
		vietnam: '越南',
		yemen: '也门',
		zambia: '赞比亚',
	},
	homePage: {
		bannerTitle: '在线接收短信到虚拟电话号码',
		bannerContent: '用于在各种网站、服务和应用程序上进行私人注册',
		bannerItem0: '70 多个国家和 10,000 多个号码',
		bannerItem1: '长短期租号',
		bannerItem2: '每天添加电话号码',
		bannerBtnText: '购买号码',
		receiveSMS: '接收短信',
		dialogTitle: '查找站点',
		dialogBtnText: '立即购买',
		dialogCellTitle: '价格',
		dialogCellTitle2: '付款条件',
		dialogCellTitle3: '国家',
		dialogPaymentWallet: '钱包',
		dialogPaymentBalance: '余额',
		firstTitle: '试用我们的虚拟号码',
		questionTitle: '常见问题',
		showOrderDialog: {
			title: '我的号码',
			timeTips: '电话号码可以使用',
			copyTips: '复制号码并在选择的站点使用它来接收短信。',
			codeText: '代号'
		},
	},
	help: {
		item0: {
			title: "我们的短信接收服务是如何工作的？",
			content: "我们使用自己的设备和sim卡，软件提供虚拟号码给用户接收消息。当我们的客户向购买的虚拟号码发送消息时，我们在sim卡上接收到它，软件将它传输到在线服务的界面。"
		},
		item1: {
			title: "什么是虚拟号码？",
			content: "这是一个可以在没有 sim 卡的情况下使用的在线号码，在我们的例子中，它是一个仅用于我们的 SMS 激活服务并且仅用于接收传入消息的号码。",
		},
		item2: {
			title: "什么情况下可以使用虚拟电话号码？",
			content: "通常，人们在社交网络（VK、OK、Facebook、Instagram）上注册、验证信使（Telegram、WhatsApp、Viber、微信）和电子邮件（Yandex、Mail.ru）时，会使用我们的虚拟号码接收短信, Gmail, Yahoo)，以及其他在线网站和流行服务：Avito、Yula、AUTO.RU、Twitter、SEOsprint、Steam、Uber、Gett、Microsoft、Tinder。此外，可以使用临时假电话号码接收在线一次性私人消息，以保持匿名，而不是购买 SIM 卡来发送一次性短信。",
		},
		item3: {
			title: "我可以得到绑定虚拟号码的SIM卡吗？",
			content: "我们的服务只是为了让人们不必购买 SIM 卡。通过我们，人们可以使用购买的在线号码而无需 SIM 卡。这些号码显示在您的个人帐户界面中，您可以在其中看到短信用于激活和确认的一次性代码。",
		},
		item4: {
			title: "服务到期后会有人获得我使用的号码吗？",
			content: "如果您收到一条发送到购买的虚拟号码的短信，那么这个号码将永远不会提供给指定站点或应用程序的任何人。因此，例如，有人会访问您的社交网络或信使的事件是完全排除在我们的服务之外。也就是说，换句话说，我们的个人号码是为每个用户单独发放的。",
		},
		item5: {
			title: "什么是一次性号码租用（从一个站点接收短信）？",
			content: "使用此资费，您将获得一个虚拟号码，使用时间从 10 分钟到 1 小时不等，具体取决于指定的国家/地区。一次性租赁服务允许人们在分配的时间内接收无限数量的 SMS 消息，并且仅来自一个选定的站点或应用程序。",
		},
		item6: {
			title: "收不到短信怎么办？",
			content: "如果您在您订购的临时号码上没有收到消息（此类事件发生的原因有多种，例如发件人网站延迟发送或发出的号码出现故障），您可以停用此电话号码，并获得资金返回余额订购新的虚拟号码。",
		},
		item7: {
			title: "我可以测试你的服务接收消息和短信激活吗？",
			content: "当然，你可以。为此，我们在这个页面上添加了一个特殊的块，其中包含用于一次性接收短信的临时免费号码。这些号码是公开的，因此它们不能用于一些流行的服务。但是与大多数激活服务不同，我们每天都会更新我们的免费号码列表。",
		},
	},
	tips: {
		errorTitle: '错误',
		successTitle: '成功',
		buyError: '请找到您要为其接收短信的站点',
		copyNumberSuccess: '复制号码成功',
		connectWalletFailText: '连接钱包失败！',
		loginSuccessText: '钱包登录成功',
		loginFailText: '使用您的钱包登录失败',
		refundFailText: '暂不退款',
		refundErrorText: '出了点问题。请刷新网站。',
		refundSuccessText: '退款成功',
		balanceFailText: '抱歉，您的余额不足',
		transferFailText: '抱歉，传输失败',
		unLoginText: '您的帐号未登录，请点击右上角的按钮登录您的帐号。',
		chainNotSupportCoin: '抱歉，本链暂不支持此类币种。'
	},
	footerLink: {
		link0: '条款和条件',
		link1: '隐私政策',
	},
	month: {
		Oct: '十月',
	}
};