import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

// 语言相关
import en from '@/locale/en'
// import ar from '@/locale/ar'
import de from '@/locale/de'
// import es from '@/locale/es'
// import fa from '@/locale/fa'
import fr from '@/locale/fr'
// import ja from '@/locale/ja'
// import ko from '@/locale/ko'
// import pt from '@/locale/pt'
// import ru from '@/locale/ru'
import zhHans from '@/locale/zh-Hans'
// import zhHant from '@/locale/zh-Hant'

export default new Vuetify({
	lang: {
		// locales: { en, ar, de, es, fa, fr, ja, ko, pt, ru, zhHans, zhHant },
		locales: { en, de, fr, zhHans },
		current: 'en',
	},
	theme: {
		options: {
			customProperties: true,
		},
		// dark: true,
		// themes: {
		// 	light: {
		// 		primary: colors.purple,
		// 		secondary: colors.grey.darken1,
		// 		accent: colors.shades.black,
		// 		error: colors.red.accent3,
		// 	},
		// 	dark: {
		// 		primary: colors.blue.lighten3,
		// 	},
		// },
	},
});
